
/* eslint-disable */

import { useVarsStore, useIncidentsStore  } from '@/store/DataStore.js';    

// ==============
//  locals
// ==============    
    
    let api_folder = "/pasahero/api"

    const _baseUrl = () => {

      let base_url = "";
      if (process.env.NODE_ENV === 'production') {

        const protocol = window.location.protocol;
        let host = window.location.host;
        let port = window.location.port;
        if ( port ) port = ":" + port;

        // live hosting
        if ( base_url.indexOf(".") ){
          api_folder = "/api"          
        }          

        base_url = protocol + "//" + host
        if ( base_url.indexOf(port) == -1) base_url += port


      } else {

        // base_url = "http://localhost";
        base_url = "http://192.168.0.10"
        
      }
      
      return base_url;

    }

    const _setDef = ( _var, def ) => {
      if ( ! _var ) return def
      return _var
    }

    const xhttp = ( method, url, data, callBackFunc ) => {
      
      const func = data.fn

      let xhr = new XMLHttpRequest();
      let formData = new FormData();

      //xhr.timeout = 5000;

      for( const name in data ){      
          formData.append(name, data[name]);  
      }
      
      xhr.open(method, url, true );
      xhr.send(formData);

      xhr.onerror = function(){        

        // url unreacheable or offline

        const storeVars = useVarsStore()
        // useFuncs.log(`error: API service unavailable - ${url}`, data)

        storeVars.show_busy = false

        // login 
        if ( func == "ln" || func == "is_online"){
          if ( callBackFunc) callBackFunc( {status: "offline"} )
          return
        }


        storeVars.snackbar_message("Unable to reach dataservice. Please try again later.", "", 10)
        return
      }

      if ( callBackFunc ){
        xhr.onload = function(){
          
          if (xhr.status == 200){
            
            const res = xhr.responseText
            let ret = res
            
            if ( data.json ){         

              try{                
            
                ret = JSON.parse(res)

              }catch(err){

                const error = {
                  error: err,
                  url: url,
                  data: data,
                  res: res,
                }
                useFuncs.log( error)
                
                if ( func == 'ln'){
                  if ( callBackFunc) callBackFunc( {status: "offline"} )
                  return
                }

                useFuncs.ShowMessage( {
                  message: 'Server connection timeout! Please try re-login.', 
                  yes_caption: 'Ok',
                  no_caption: "-",
                  persistent: true,
                  yes_func: () => {
                    const url = useFuncs.BaseURL()                      
                    window.location.replace(url)
                  }
                });
                return
              }
            } 

            callBackFunc( ret );
          }
        };
      }

    }

// =======================


export const useFuncs = {  

  DEV_MODE: () => {
    // return false
    return process.env.NODE_ENV === "development"
  },

  BaseURL: () => {

    const protocol = window.location.protocol;
    let host = window.location.host;
    let port = window.location.port;
    if ( port ) port = ":" + port;

    let base_url = protocol + "//" + host
    if ( base_url.indexOf(port) == -1) base_url += port

    return base_url
  },

  Post: (data, callBackFunc ) => {
    const base_url = _baseUrl();   
    const url = base_url + api_folder + "/api.php"

    // console.log('url', url)
    xhttp( "POST", url, data, callBackFunc )
  },
  
  ResetObject : ( data ) => {

    for( const key in data){

      if ( Array.isArray( data[key] )){
        data[key] = []
      }else{
        data[key] = ''
      }
    }
  },

  RemoveEmpty : (obj) => {

    for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
            delete obj[key];
        }
    }

  },

  DateTimeFormat( sDate, date_format = "Y-m-d", time_format = "h:m ap"){

    return this.DateFormat( sDate, date_format ) + " " + 
      this.TimeFormat( sDate, time_format )
  },

  DateFormat(sDate, format = 'Y-m-d'){

    // format : Y-m-d , y-M-d, y-M-d D

    let date = new Date(sDate);

    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let dayOfWeek = date.getDay();
    
    let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

    let m = (month +1).toString().padStart(2, "0");
    let d = day.toString().padStart(2, "0");
    let y = year;
    let y2 = y.toString().substr(0,2);
    var ret = format;

    // day
    ret = ret.replace("d", d);
    
    // year
    ret = ret.replace("Y", y);
    ret = ret.replace("y", y2);

    // Day name
    ret = ret.replace("D", days[dayOfWeek]);

    // month
    ret = ret.replace("M", months[month]);
    ret = ret.replace("m", m);
     
    return ret;
  },

  TimeFormat( sDateTime, format = "h:m ap"){
    // format : h:m a = 09:30 AM

    const anyDate = "2024-01-01 ";

    let time = new Date(sDateTime);

    let hour = String(time.getHours()).padStart(2, "0");
    let minute = String(time.getMinutes()).padStart(2, "0");
    let sec = String(time.getSeconds()).padStart(2, "0");

    let ap = "AM";
    if ( format.indexOf("ap") > -1 ){        

        if ( hour > 12 ){
          hour = hour - 12;
          ap = "PM";        
        }
    }

    let ret = format;
    ret = ret.replace("h", hour);
    ret = ret.replace("m", minute);
    ret = ret.replace("s", sec);
    ret = ret.replace("ap", ap);

    return ret;
  },

  PicFileName( file_name ){
    
    const storeVars = useVarsStore()

    storeVars.att_file_index ++
    const ext = file_name.slice((file_name.lastIndexOf(".") - 1 >>> 0) + 2);

    const date = new Date();
    const sdate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() }`
    const name = useFuncs.DateFormat( sdate, `Ymd-${storeVars.att_file_index}-cam-pic.`) + ext
    return name

  },

  AttachFiles( postVar ){

    const { att_files } = useVarsStore()
    for( const i in att_files ){        
      postVar["files_" + i] = att_files[i]
    }
  },

  AttForDelete( id ){
    useVarsStore().att_files_for_delete.push(id)
  },

  AttFilesDelete( callBack = ""){

    const { att_files_for_delete } = useVarsStore()

    // delete removed images
    if ( att_files_for_delete ){
      
      this.Post( {fn: 'remove', table:4, ids: att_files_for_delete.join(), json: 1 }, 
      (ret) => {        

        if ( callBack ) callBack()          
      })
      return
    }

    if ( callBack ) callBack()

  },
  
  SetAvatar(){
    
    const storeVars = useVarsStore()

    this.Post( {fn: 'fetch_image', id: storeVars.user_profile.avatar_id, json:1}, (ret) => {
      
      const url = _baseUrl() + api_folder + "/" + ret.img
      storeVars.user_profile.avatar = url
    })

  },
  
  ImageURL( id, ref_url = "", callback = "" ){

    const storeVars = useVarsStore()

    storeVars.show_busy = true
    this.Post( {fn: 'fetch_image', id: id, json:1}, (ret) => {

      storeVars.show_busy = false
      const url = _baseUrl() + api_folder + "/" + ret.img

      if ( ref_url ) ref_url.value = url
      if ( callback ) callback(url)
    })
      
  },

  ViewImage( img_rec){
    
    const storeVars = useVarsStore()

    storeVars.show_busy = true

    this.Post( {fn: 'fetch_image', id: img_rec.id, json: 1}, (ret) => {

      // console.log('ret',ret)
      
      const url = _baseUrl() + api_folder + "/" + ret.img
      storeVars.image_viewer.link = url      
      storeVars.image_viewer.title = img_rec.file_name
      storeVars.image_viewer.show = true          

      storeVars.show_busy = false

    }) 
      
  },

  PreviewCapturedImage( inputRef, callback ){
  
    if ( ! inputRef.value.files ) return
      
    const file = inputRef.value.files[0]
    const reader = new FileReader();

    reader.onload = function() {            
      if ( callback ) callback( reader.result)
    };

    reader.readAsDataURL(file);
  },  
  
  CustomTypes : ( types ) =>{

    return {

      Title( value, def = "" ){   
        const item = types.find( i => i.value == value )
        return item ? item.title : def
      },

      Value( title, def = "" ){
        const item = types.find( i => i.title == title )
        return item ? item.value : def

      },

      Icon( value, def = "" ){
        const item = types.find( i => i.value == value )
        return item ? item.icon : def
      },

      Color( value, def = ""){
        const item = types.find( i => i.value == value )
        return item ? item.color : def        
      },

      // Static method
      // static isCar(car) {
      //   return car instanceof Car;
      // }
    }
  }, 

  ShowMessage: ( options ) => {

    const storeVars = useVarsStore();

    let { title, message, color, yes_caption, no_caption, persistent, yes_func } = options

    storeVars.prompt_message.title = _setDef( title, '')
    storeVars.prompt_message.message = message
    storeVars.prompt_message.color = _setDef( color, 'red' )
    storeVars.prompt_message.yes_caption = _setDef( yes_caption, 'Yes')
    storeVars.prompt_message.no_caption = _setDef( no_caption, 'No')
    storeVars.prompt_message.persistent = _setDef(persistent, false)

    if ( yes_func )
      storeVars.prompt_message.yes_func =  yes_func
    
    storeVars.prompt_message.show = true;

  },


  DateTimeCol( datetime ){
    
    const now = this.DateFormat( Date.now(), "M d, Y")

    let date_time = this.TimeFormat( datetime )
    const dt = this.DateFormat( datetime, "M d, Y")

    if ( dt !== now ) date_time = dt + "<br>" + date_time

    return date_time
  },
  
  ResizeImageQuality( file, maxSizeKB, callback) {

    const isImage = file.type.indexOf('image') > -1
    const kb = file.size / 1024 
    if ( ! isImage || kb < maxSizeKB ) {
        callback( null )
        return;
    }

    // add canvas for redrawing in html body
    let canvasId = 'resizeCanvas'
    const body = document.getElementById("image_div");            
    if (body){

        let e = document.getElementById(canvasId);
        if ( ! e ){

            let canvas = document.createElement('canvas');
            canvas.id = canvasId;   
            // canvas.style.display = "none";
            body.appendChild(canvas);
            // console.log( 'canvas created')
        }
    }

    const reader = new FileReader();
    reader.onload = (event) => {
    
        const img = new Image();
        img.onload = () => {

            const canvas = document.getElementById( canvasId);
            const ctx = canvas.getContext('2d');

            // Calculate the new dimensions
            const width = img.width;
            const height = img.height;
            let resize_percent = .95;

            // Set the canvas dimensions and draw the image
            const resizeIt = () => {

                const w = width * resize_percent
                const h = height * resize_percent

                canvas.width = w
                canvas.height = h
                ctx.drawImage(img, 0, 0, w, h)

                // Convert the canvas to a Blob and pass it to the callback
                canvas.toBlob( (blob) => {

                    const kb = blob.size / 1024;
                    // console.log( 'resized ', ' kb:', kb, ' max:', maxSizeKB , 'resize percent:', resize_percent * 100, "%")

                    if ( kb <= maxSizeKB || resize_percent <= 0 ){
                        callback(blob, resize_percent )
                    }else{
                        resize_percent -= 0.05
                        resize_percent = Math.floor(resize_percent * 100) / 100
                        resizeIt();
                    }

                }, file.type );
            }

            resizeIt()
        }

        img.src = event.target.result
    }

    reader.readAsDataURL(file);

    /*
    const isImage = file.type.indexOf('image') > -1
    const mb = file.size / (1024 * 1024) 
    if ( ! isImage || mb < maxSizeMB) {
        callback( null )
        return;
    }

    // add canvas for redrawing in html body
    let canvasId = 'resizeCanvas'
    const body = document.getElementById("image_div");            
    if (body){

        // sched request                
        let e = document.getElementById(canvasId);
        if ( ! e ){
            let canvas = document.createElement('canvas');
            canvas.id = canvasId;   
            canvas.style.display = "none";
            body.appendChild(canvas);
        }
    }

    let image = new Image();
    let reader = new FileReader();

    reader.onload = function(event) {

        image.onload = function() {

            var width = image.width;
            var height = image.height;

            var canvas = document.getElementById( canvasId );
            var context = canvas.getContext('2d');
            
            var quality = 0.95

            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);

            const resizeIt = () => {                                                
                canvas.toBlob( (blob) => repeatOrDone(blob), file.type, quality );               
            }

            const repeatOrDone = (blob) => {

                const mb = blob.size / (1024 * 1024);
                console.log( 'resize or done', 'mb:', mb, 'size:', blob.size, 'quality:', quality)

                if ( mb < maxSizeMB ){                  
                  callback(blob)
                }else{
                  quality -= 0.5
                  resizeIt();
                }
            }
            
            resizeIt()
        };
        image.src = event.target.result;
    };

    reader.readAsDataURL(file);
    */
  },
  
  PasswordRule( password ){

    const withUppercase = /[A-Z]/.test(password)
    const withLowercase = /[a-z]/.test(password)
    const withNumber = /[0-9]/.test(password)
    const withSpecial = /[#?!@$%^&*-]/.test(password)

    if ( ! withUppercase ) return 'At least 1 upercase letter.'
    if ( ! withLowercase ) return 'At least 1 lowercase letter.'
    if ( ! withNumber ) return 'At least 1 number.'
    if ( ! withSpecial ) return 'At least 1 special character.'
    if ( password.length < 6) return "At least 6 character length."

    return "success"
  },

  ProperCase(str) {
    return str.replace(/\b\w/g, function(char) {
      return char.toUpperCase();
    });
  },

  TrackingNo( type, id){

    const code = {
      [ useVars.CAT_Incidents ]: 'IN',
      [ useVars.CAT_LeftItems ]: 'LI',
      [ useVars.CAT_DriverComplains ]: 'DC',
      [ useVars.CAT_DriverRatings ]: 'DR',
      [ useVars.CAT_SafetySelfies ]: 'SS',
    }
    return code[type] + id.padStart(5,0)
  },

  ProfileName( profile ){

    let name = profile.last + ", " + profile.first
    if ( profile.middle ) name += " " + profile.middle[0] + "."
    if ( profile.alias ) name += " - '" + profile.alias + "'"
    return name
  },

  log: ( ...msg ) => {
    
    if ( ! useFuncs.DEV_MODE() ) return

    const storeVars = useVarsStore()

    if ( storeVars.is_mobile ){

      alert( JSON.stringify(msg) )
  
    }else{
      console.log( ...msg) ;
    }

  },

  local: {

    add: ( key, obj_value ) => {

      // add to existing key
      let value = localStorage.getItem( key )
      if ( value ){

        value = JSON.parse( value )
        value = [obj_value, ...value]
      
      }else{
        value = [obj_value]
        
      }      
      localStorage.setItem(key, JSON.stringify(value) )

      useFuncs.log(value)
      return value
    },

    delete: (key, index ) => {

      // get
      const data = JSON.parse( localStorage.getItem( key ) )
      data.splice( index, 1)

      // set it back 
      localStorage.setItem( key, JSON.stringify(data) )
      return data
    },
    
    get: (key) => {
      let value = localStorage.getItem( key )
      if ( ! value ) return false
      
      return JSON.parse( value )
    },

  },

}
    

export const useRuleNotEmpty = [  
  v => {     
    if ( typeof v === 'object') return true
    if ( parseInt(v) > 0 ) return true
    if ( v ) if (v.length > 0 ) return true
    return "Empty field"  
  }
]

export const useRulePassword = [  
  v => {     
    if ( v ) if (v.length > 0 ){

      const ret = useFuncs.PasswordRule(v)
      if ( ret == "success" ) return true
      return ret

    } 
    return "Empty field"  
  }
]

 

export const useVars = {
  
  profile_types : [
    {title: 'Driver', value: 1},
    {title: 'Operator', value: 2},
    {title: 'Driver/Operator', value: 3},
    {title: 'Respondent', value: 4},
    // {title: 'Police Station', value: 5},
    // {title: 'Fire Station', value: 6},    
    {title: 'PasaHero', value: 50},    
    {title: 'Other', value: 100},
  ],
  
  PROFILE_Driver: 1,
  PROFILE_DriverOperator: 3,
  PROFILE_PasaHero: 50,
  PROFILE_Admin: 60,

  SOURCE_Web: 1,
  SOURCE_Mobile: 2,

  TABLE_Profiles: 1,
  TABLE_Vehicles: 2,
  TABLE_Incidents: 3,
  TABLE_Comments: 5,
 
  CAT_LeftItems : 1,
  CAT_Incidents : 2,
  CAT_DriverComplains : 3,
  CAT_SafetySelfies : 4,
  CAT_DriverRatings : 5,
  CAT_FareMatrix: 6,

  category_types:[
    {title: 'Left Item', value : 1},
    {title: 'Incident',  value: 2},
    {title: "Driver Complain", value : 3},
    {title: "Safety Selfie", value : 4},
    {title: "Driver Rating", value : 5},
  ],

  incident_types : [
    {title: 'Accident', value: 1},
    {title: 'Violence', value: 2},    
    {title: 'Fire', value: 3},
    {title: 'Other', value: 100},
  ],

  
  driver_complain_types :[    
    {title: 'Overcharging', value: 1},
    {title: 'Attitude', value: 2},
    {title: 'Ride Refusal', value: 3},    
    {title: 'Other', value: 100},
  ], 

  STATUS_New: 1,
  STATUS_Pending: 2,
  STATUS_Dispatched: 3,
  STATUS_Resolved: 4,

  status_types: [
    {title: 'New',        value: 1, icon: "mdi-alert-octagram", color: "red"},
    {title: 'Pending',    value: 2, icon: "mdi-pause-octagon",  color: "orange"},
    {title: 'Dispatched', value: 3, icon: "mdi-eye-check",      color: 'blue'},
    {title: 'Resolved',   value: 4, icon: "mdi-check-circle",   color: 'green'},        
  ],

  bg : {
    1: 'green-darken-4',        // #1B5E20
    2 : 'red-darken-4',          // #B71C1C    
    3: 'blue-darken-4',   // #0D47A1 
    4: 'orange-darken-4',   // #E65100
    5: 'purple-darken-4',   // #4A148C
    6: 'teal-darken-4'    // #263238
  },

  field_props : {density: "compact", variant:"outlined", hideDetails:"auto"}, 

  submit_text: "Before proceeding, please note that the information you have provided must be valid, accurate, reliable, and appropriate. You will be held accountable for misuse or dissemination of false and misleading information.",

  offline_colors : {
    qr_vehicle: 'bg-green-darken-1',
    qr_driver: 'bg-blue-darken-2',
    pic: 'bg-orange-darken-2',
  },

}



/*
accident
 - accident_type
 - other_name
 - description
 - location
 - image_id
 - respondent

violence
 - violence_type
 - other_name
 - description
 - location
 - image_id
 - respondent

fire
 - location
 - description
 - image_id
 - respondent

Driver Rating
 - driver_id
 - driver_name
 - description
 - Rate
 - repondent

Complaint
 - complainee_id
 - description
 - image_id
 - complainant_id


Complaint
*/ 