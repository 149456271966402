<script setup>
  /* eslint-disable */
  
  import { ref, onMounted, computed } from 'vue'
  import { useRouter } from 'vue-router';

  import { useVarsStore } from '@/store/DataStore.js'
  import { useVars, useFuncs } from '@/composables/useFunctions.js'

  import NavBar from '@/components/NavBar'
  import BusyCircle from '@/components/parts/BusyCircle'
  import MessagePrompt from '@/components/parts/MessagePrompt'
  import ViewImage from '@/components/ViewImage'

  const storeVars = useVarsStore()
  const router = useRouter()

  const user_features =ref([])
  const features_copy = []

  const {
    CAT_Incidents,      CAT_LeftItems,      CAT_DriverComplains, 
    CAT_DriverRatings,  CAT_SafetySelfies,  CAT_FareMatrix,
    field_props 
  } = useVars


  // ==== functions ======
    function handleTouchMove(event) {
      // event.preventDefault();
      storeVars.is_mobile = true
    }

    const cancel_settings = () => {

      storeVars.show_setting = false

      // restore values
      storeVars.clone.user_features = [...storeVars.user_features]

    }
    const save_settings = () => {

      storeVars.show_busy = true
      storeVars.show_setting = false

      storeVars.user_features = [...storeVars.clone.user_features]

      useFuncs.Post( {fn: 'ss', t: 'USERPREF', d:'Features', c: storeVars.user_profile.id,
        s: storeVars.user_features.join(), xp:'t,d,c', json:1 }, 
        (ret) => {        
        // console.log( "settings", ret)        
        storeVars.show_busy = false

        storeVars.app_drawer = false
      })

    }

    /*
    const connection_status_prompt = ( is_online ) => {

      let msg, action
      if ( is_online ){

        msg = "Your internet connection restored."
        
        // currently offline
        if ( storeVars.connection.offline_mode ){
          msg += " System will redirect to login screen."
          action = "re-login"
        }

      }else{

        msg = "You're device is offline."
        if ( storeVars.is_logged_in ){
          msg = "You're internet connection lost. System will now logoff."
          action = "logoff"
        }
      }

      // storeVars.prompt_message.title = "Connection Status"
      useFuncs.ShowMessage({
        title: 'Connection Status',
        message: msg,
        color: 'bg-grey-darken-2',
        yes_caption: 'Ok',
        no_caption: '-',        
        persistent: true,
        yes_func: () => {
          useFuncs.log( 'action', action)
          if ( action == "re-login" || action == "logoff"){

            storeVars.connection.offline_mode = false
            storeVars.is_logged_in = false
            storeVars.app_drawer = false
            storeVars.user_profile = {}
            router.push("/login")

          }
        }
      })

    }

    const IsOnline = () => {

      if ( storeVars.connection.is_checking ){
        //console.log( 'already checking')
        return
      } 

      storeVars.connection.is_checking = true;
      useFuncs.Post( {err_no_prompt: 1}, (res) => {

        //console.log('res', res)
        const is_online = res == "offline" ? false : true;
        
        // prompt message if not same status
        if ( is_online !== storeVars.connection.is_online ){
          connection_status_prompt(is_online)
        }
        storeVars.connection.is_online = is_online;

        setTimeout( () => {

          storeVars.connection.is_checking = false;
          IsOnline()

        }, 3000 )                
        
      })        


    }
    IsOnline();
    */
   


  // === hooks ==========
    onMounted(() => {

      // check if online
      useFuncs.Post( {fn: 'is_online'}, (ret) => {

        if ( ret.status == "offline" ){
          
          storeVars.connection.is_online = false;

          useFuncs.ShowMessage({
            title: 'Connection Status',
            message: "You're device is offline.",
            color: 'bg-grey-darken-2',
            yes_caption: 'Ok',
            no_caption: '-',        
            persistent: true,
          })

        }
      })        

      const touchArea = document.getElementById('touch-test');
      touchArea.addEventListener('touchmove', handleTouchMove);    
      // touchArea.touchmove()

      if ( ! storeVars.is_logged_in ){
        router.push("/login")
      }
      
    })

  // ===== computed ===========
    const features = computed( () => {

      return [
        {title: 'Incident Reporting', type: CAT_Incidents, status: true},
        {title: 'Left Items Reporting', type: CAT_LeftItems, status: true},
        {title: 'Driver Complain Reporting', type: CAT_DriverComplains, status: true},
        {title: 'Driver Rating', type: CAT_DriverRatings, status: true},
        {title: 'Safe Ride Selfie', type: CAT_SafetySelfies, status: true},
      ]     

    })



</script>



<template>

  <v-app :class="storeVars.view_bg" id="touch-test">


      <NavBar 
        v-if="storeVars.is_logged_in || storeVars.connection.offline_mode == true"  
      />  
            
      <v-main 
      v-if="storeVars.is_logged_in || storeVars.connection.offline_mode == true"
      >
        <v-container>
          <router-view/>
        </v-container>
      </v-main>    
      
      <div
      v-else 
      class="fill-height d-flex align-center justify-center"
      >
        <v-container>
          <router-view/>
        </v-container>
      </div>


      <BusyCircle />
      <MessagePrompt />
      <ViewImage />
  
      <!-- snackbar message -->
      <v-snackbar
        v-model="storeVars.message.show"
        :timeout="storeVars.message.msecs"
        :color="storeVars.message.color"
        multi-line
        > 
        <p v-for="(txt, index) in storeVars.message.text" :key="index">
        {{ txt  }}
        </p>
        <template v-slot:actions>
          <v-btn
            color="blue"
            variant="text"
            @click="storeVars.message.show = false"
          >
            {{ storeVars.message.btn_caption }}
          </v-btn>
        </template>
      </v-snackbar>
      
      <!-- settings -->
      <v-bottom-sheet v-model="storeVars.show_setting" persistent>
        
        <div class="bg-white pa-3">

          <div 
            class="d-flex"
            style="margin-top: -30px;"            
          >
            <v-btn 
              class="ml-4"
              icon="mdi-arrow-left" 
              color="grey-lighten-2" 
              density="comfortable"              
              @click="cancel_settings"              
            ></v-btn>

            <v-spacer />

            <v-btn 
              class="mr-4"
              icon="mdi-check" 
              color="primary" 
              density="comfortable"              
              @click="save_settings"              
            ></v-btn>

          </div>

          <div class="mt-3">
            <p class="text-h6">Features:</p>
            <v-divider></v-divider>
            
            <div 
              v-for="(item, index) in features" :key="index"
              class="d-flex align-center px-5">
              <p>{{ item.title }}</p>
              <v-spacer />
              <v-switch
                v-model="storeVars.clone.user_features"
                :value="item.type"
                v-bind="field_props"
                color="primary"                
              ></v-switch>
            </div>
          </div>
        </div>
      </v-bottom-sheet>

  </v-app>

  <!-- for resizing images -->
  <div id="image_div" class="d-none"></div>

</template>

<style>
  .bg-01{background-color: rgba( 0, 0, 0, .1 ) !important }
  .bg-02{background-color: rgba( 0, 0, 0, .2 ) !important }

  .h-200{ height: 200px; }
  .h-250{ height: 250px; }
  .scroll-y{overflow-y: auto;}

</style>