
<script setup >
/* eslint-disable */

import { useVarsStore } from '@/store/DataStore'

const storeVars = useVarsStore();

</script>

<template>

	<v-dialog
    v-model="storeVars.show_busy"
    max-width="50px"   
    theme="light"
  >
		<v-progress-circular
		  color="primary"
		  indeterminate
		  size="50"
		  width="6"
		></v-progress-circular>	

	</v-dialog>

</template>

