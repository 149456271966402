
<script setup >
	/* eslint-disable */
	
	import { ref, onMounted } from 'vue'
	import { useRouter } from 'vue-router';
  import { useFuncs } from '@/composables/useFunctions.js';
  import { useVarsStore } from '@/store/DataStore.js'

  const img_url = ref('')  

  const router = useRouter()
  const storeVars = useVarsStore()

  // =============== functions ===========
    
    const back_home = () => {
      
      storeVars.NavBack(false)
      storeVars.view_bg = "bg-blue-grey-lighten-4"
      router.push("/")   
    }

		onMounted(() => {

			if ( storeVars.is_logged_in ){

	    	useFuncs.ImageURL( 202, img_url)

	    }else{

	    	const local_img = localStorage.getItem('fare-matrix-img')
	    	useFuncs.log( local_img)
	    	if ( local_img )
	    		img_url.value = local_img
	    }

		})

</script>

<template >
	
	<div class="" >	
	
		<img class="rounded"
			:src="img_url" style="width: 100%">				
		</img>

		<div class="d-flex justify-end mt-1">
	    <v-btn 
	    class="rounded-xl ml-2 text-black "
	    @click="back_home"
	    >
	      <v-icon class="text-orange mr-2">mdi-arrow-left-circle</v-icon>
	      Back
	    </v-btn>  
    </div>

	</div>	
	
</template>

