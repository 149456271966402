<script setup>
	/* eslint-disable */

  import { ref, computed, onMounted, defineProps } from 'vue'

  import { useVarsStore } from '@/store/DataStore.js'
  import { useVars, useFuncs } from '@/composables/useFunctions.js'  

  const props = defineProps(['type'])

  const storeVars = useVarsStore()

  const {
    CAT_Incidents,      CAT_LeftItems,      CAT_DriverComplains, 
    CAT_DriverRatings,  CAT_SafetySelfies,  CAT_FareMatrix,  
    status_types,
  } = useVars

  const entries = ref([])
  const local_data = ref([])
	
  onMounted(() => {

    storeVars.show_busy = true

    useFuncs.Post({fn: 'n3s', id: storeVars.user_profile.id, tp: props.type, json:1}, (ret) => {
      
      storeVars.show_busy = false

      // console.log( 'history entries '+ props.type, ret)

      const data = []
      for(const item of Object.values(ret.data) ){

        if ( props.type == CAT_DriverRatings ){
          item.color = "grey"
        }else{
          item.color = useFuncs.CustomTypes(status_types).Color( item.status )
        }

        item.date_time = useFuncs.DateFormat( item.datetime, "M d, Y") + " " +
          useFuncs.TimeFormat( item.datetime)
        item.date = useFuncs.DateFormat( item.datetime, "M d, Y")

        item.tracking_no = useFuncs.TrackingNo( props.type, item.id)
        data.push( item )

      }

      entries.value = data
    })


      // const keys = {
      //   [CAT_Incidents]: 'incidents',
      //   [CAT_SafetySelfies]: 'selfie',
      // }
      // const key = keys[ props.type ]

      // const local_data = useFuncs.local.get( key )

      // if ( local_data ){

      // }

  })

  const color_legend = () => {
    storeVars.snackbar_message("Status color legend:<br>Red means NEW<br>Blue means SEEN<br>Orange means PENDING<br>Green means Already RESOLVED", 
        "green-darken-4", 10)
  }


</script>

<template>

  <div class="">

    <div v-if="entries.length" class="d-flex align-center mt-5">
      <p class="text-body-2 mr-1 ">Posts: </p>
      <v-icon  size="small" color="black" 
        @click="color_legend"
      >mdi-help-circle</v-icon>
    </div>

    <div v-if="entries.length"
      class="d-flex flex-wrap bg-01 pa-2 rounded "
    >

      <v-chip
        v-for="(item, index) in entries" :key="index"      
        :color="item.color"
        variant="elevated"
        class="ma-1"
        density="default"
        @click="storeVars.PostRecord(props.type, item.id)"
      >
        <p class="text-body-2 text-white">
        {{ item.tracking_no}}        
        </p>
      </v-chip>

    </div>
      
  </div>


</template>

