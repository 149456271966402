<script setup>
  /* eslint-disable */


  import { defineEmits, defineProps } from 'vue'

  const props = defineProps(['can_delete', 'save_title', 'cancel_title'])
  const emits = defineEmits(['save-click', 'cancel-click', 'delete-click'])

  const save = () => emits("save-click")
  const cancel = () => emits("cancel-click")
  const deleteIt = () => emits("delete-click")


</script>


<template>
  
  <div class="d-flex pa-3 bg-grey-lighten-4" align="end">

    <v-btn      
      v-if="props.can_delete"
      @click="deleteIt"  
    >
      <v-icon class="mr-2" color="red">mdi-trash-can</v-icon>
      Delete
    </v-btn>

    <v-spacer></v-spacer>

    <v-btn 
    class="mr-2 rounded-xl"
    @click="save"
    >
      <v-icon class="text-green mr-2">mdi-check-circle</v-icon>
      {{ props.save_title ? props.save_title : 'Submit' }}
      </v-btn>            

    <v-btn 
    class="rounded-xl"
    @click="cancel"
    >
      <v-icon class="text-orange mr-2">mdi-arrow-left-circle</v-icon>
      {{ props.cancel_title ? props.cancel_title : 'Back' }}
    </v-btn>            
  </div>

</template>

