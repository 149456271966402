
<script setup>
  /* eslint-disable */

  // imports
  import { ref, computed, watch, onMounted } from 'vue';
	import { useRoute, useRouter } from 'vue-router';

  import SaveCancel from '@/components/parts/SaveCancel.vue';
  import AttachFiles from '@/components/parts/AttachFiles.vue';
  import ImagesChip from '@/components/parts/ImagesChip.vue'

  import { useVarsStore } from '@/store/DataStore';

  import { useFuncs, useRuleNotEmpty, useRulePassword, useVars } from '@/composables/useFunctions.js';

  const router = useRouter()
  const route = useRoute()

  const { field_props } = useVars

  const storeVars = useVarsStore()

  const ui = ref({
  	title: "Account Registration",
  	margin: 'ma-3 mt-5',  
  })

  const data = ref({    
    prefix: '', suffix: '', last: '', first: '', middle: '', alias: '',
    birthdate: '', civil_status: '', sex: '',     
    house_street_no: '', village: '', barangay: '', city:'', province: '',
    mobile_nos: '', phone_nos: '',  emails: '',
    username: '',
  })
  const password = ref('')
  const confirm = ref('')
  const confirm_error = ref('')

  const image_src = ref('')
  
  const form = ref(null)
  const cam_pic = ref(null)

  let is_read_only = false;

  const color = ref({
  	pass_icon: 'grey',
  	confirm_icon: 'grey',
  })

  const pass_input = ref({
  	icon: 'mdi-eye-off',
  	type: 'password',
  })

  const id_pic = ref(null);
  const id_pictures = ref([]);

  // =========
  // funcs
  // =========
 		
 		const editProfile = () => {

 			const p = {fn: 'fetch', table: 1, fields: 'user-fields',
 				rec_id_cond: storeVars.user_profile.id, one: 1,
 				json: 1 }

 			storeVars.show_busy = true
 			
 			id_pictures.value = []

 			useFuncs.Post( p, (ret) => {
 				// console.log('edit user profile', ret)

 				data.value = ret.data

 				// avatar
 				if ( ret.images ){

 					// console.log('img id', ret.images[0].id)
 					const av_id = ret.images[0].id
 					storeVars.user_profile.avatar_id = av_id
 					useFuncs.ImageURL( av_id, "", (url) => image_src.value = url ) 					
					
					ret.images.shift()
					id_pictures.value = ret.images
					console.log( 'images', ret.images)
					
 				}

				storeVars.show_busy = false
 			})

 		}

    // apply in database
    const postIt = ( p ) => {

    	// remove deleted id's
    	useFuncs.AttFilesDelete()

      // attach files
      useFuncs.AttachFiles( p )

      // attach id
      if ( id_pic.value )
      	p['file_idpic'] = id_pic.value;

      p.table = useVars.TABLE_Profiles;      

      if ( storeVars.is_logged_in ){
      	
      	p.fn = "update_acct"
      	p.rec_id_cond = storeVars.user_profile.id

      	if ( id_pic.value )
	      	p.avatar_id = storeVars.user_profile.avatar_id
	      

      }else{
	      p.fn = "insert"     
	      p.password = password.value
	    }

      useFuncs.Post( p, ( ret ) => {
       
        storeVars.show_busy = false

        const msg = storeVars.is_logged_in ? "Profile successfuly updated." : "Registration Successful. Your are now a member of PasaHero community."        
        storeVars.snackbar_message(msg, 10)

				// avatar
				if ( storeVars.is_logged_in ){
	      	
	      	const avatar_id = ret.images[0].id
	      	storeVars.user_profile.avatar_id = avatar_id
	      	useFuncs.SetAvatar()

	        const {last, first, middle } = p
		      const name = `${last}, ${first} ${middle.charAt(0)}.`	      
		      storeVars.user_profile.name = useFuncs.ProperCase(name);

		    	router.push("/")  

	      }else{

		      // back to login url
	        const url = useFuncs.BaseURL()        
			    window.location.replace(url)

				}

      })
    }
		
    
    const submit = async () =>{

      const { valid } = await form.value.validate()

      if ( ! valid){
      	storeVars.snackbar_message("Please fill in empty fields.")
      	return
      }

      // during registration
      if ( ! storeVars.is_logged_in ){      	

	      if ( ! cam_pic.value.files.length ){
	      	storeVars.snackbar_message("Please take a selfie as your profile picture.")
	      	return
	      }

	      // if no ID
	      if ( ! storeVars.att_files.length ){
	      	storeVars.snackbar_message("Please upload id picture.")
	      	return	
	      }
	    }


	    // check if all id are deleted
      if ( storeVars.is_logged_in ){      	
      	
      	if ( storeVars.att_files_for_delete.length == id_pictures.value.length && ! storeVars.att_files.length){
	      	storeVars.snackbar_message("Please upload id picture.")
	      	return	      	
      	}
      }


      storeVars.show_busy = true

      const p =  Object.assign({}, data.value);  // copy the data only   
      p.type = useVars.PROFILE_PasaHero
      p.json = 1    
      
      if ( storeVars.is_logged_in ){

      	// update profile
      	postIt(p)

      }else{

      	// add profile

	      const p_check = Object.assign({}, p)        
	      p_check.fn = 'pasahero-exists'             
	      useFuncs.RemoveEmpty(p_check) 

	      // check existing
	      useFuncs.Post( p_check, (ret) => {
	        
	        // console.log('registration exists', ret)

	        let msg
	        if ( ret.username_exist ) msg = "Username already in use. Please try another username."
	        if ( ret.email_exist ) msg = "Email already in use. Please try another email address."

	        if ( msg ){
	          storeVars.show_busy = false
	          storeVars.snackbar_message(msg, 5)          
	          return
	        }        
		      postIt(p)                      
	        
	      })       

	    }
    }
   	
	  const previewImage = () =>{
	  	
	  	storeVars.show_busy = true

	  	useFuncs.PreviewCapturedImage( cam_pic, ( src ) => {  		
	      image_src.value = src

				// resize pic	      
	      const file = cam_pic.value.files[0]
	      useFuncs.ResizeImageQuality(file, 200, (blob) => {

	      	let name = "0." + file.name.split('.').pop()

	        if ( blob !== null ){       	                    
	          id_pic.value = new File( [blob], name, { type: file.type } )

	        }else{
						id_pic.value = new File([file], name, { type: file.type })

	        }

	        storeVars.show_busy = false
	      })

	  	})

	  }	

	  const pass_input_mode = () => {

	  	pass_input.value.icon = pass_input.value.icon == "mdi-eye-off" ? "mdi-eye" : "mdi-eye-off" 	
	  	pass_input.value.type = pass_input.value.icon == "mdi-eye-off" ? "password" : "text"
	  	
	  }

	  const emailRule = [ 
        v => {
        	if ( !v ) return "Empty field"
        	if ( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ) return true
        	return 'Invalid email address'
        }
     ]
  		

  // =========
  // watcher
  // =========

	  watch( password, (newVal) => {	  	
	  	color.value.pass_icon = useFuncs.PasswordRule( newVal ) == "success" ? "secondary" : "grey"
    })

	  watch( confirm, (newValue) => {
	  	const same = password.value == newValue

	  	color.value.confirm_icon = same ? "secondary" : "grey"
	  	confirm_error.value = same ? "" : "Incorrect confirm password"

	  })

	// ============
	// hooks
	// ============

		onMounted( () => {

			if ( storeVars.is_logged_in ){

				ui.value.title = "My Profile"
				ui.value.margin = ""

				storeVars.view_bg = "bg-blue-grey-darken-4"

		    is_read_only =  route.params.qr == "ro"

				editProfile()
			}

			if ( storeVars.user_profile.is_google_login){
				image_src.value = storeVars.user_profile.avatar
			}
		})


</script>

<template> 

	<input ref="cam_pic" capture="camera" type="file" accept="image/*"  
		hidden @change="previewImage()"
	>


	<div :class="`w-90 mb-10 ${ui.margin} `">
		
		<p class="text-h5 font-weight-bold text-center mb-2">{{ui.title}}</p>
    
    <v-card class='' >
			
			<v-card-text>			

      <v-form  ref="form"  >

      	<p class="text-caption">Profile Picture:</p>
				<v-row>
					<v-col class="d-flex align-center justify-center">				

						<v-btn 							
							v-if="! storeVars.user_profile.is_google_login && ! storeVars.profile_no_save"
							density="comfortable"							
							icon="mdi-camera" 
							@click="cam_pic.click()"							
						></v-btn>

						<v-card width="150" height="150" 
							class="bg-blue-grey-darken-4 ml-3 rounded-circle " >

							<v-img  height=150 :src="image_src" 
							cover 

							></v-img>
							
						</v-card>

					</v-col>

				</v-row>       
			
				<v-divider class="my-5"/>				

				<p class="text-caption mb-3">Personal Details:</p>
		    <v-row>	      	
					
	        <v-col cols="12" class="no-padding">
	          <v-text-field  required label="Last:" v-bind="field_props" 
	          :rules="useRuleNotEmpty"
	          v-model.trim ="data.last" 	         
	          > </v-text-field>
	        </v-col>

	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="First:" v-bind="field_props" 
	          :rules="useRuleNotEmpty"
	          v-model.trim = "data.first"
	          > </v-text-field>
	        </v-col>
	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="Middle:"  v-bind="field_props" 	          
	          v-model="data.middle"
	          > </v-text-field>
	        </v-col>

	        <v-col cols="6" v-if="storeVars.is_logged_in">
	          <v-text-field label="Alias/Nickname:" v-bind="field_props"
	          v-model="data.alias"
	          ></v-text-field>
	        </v-col>

	        <v-col cols="6" v-if="storeVars.is_logged_in">
	          <v-text-field label="Birthdate:" type="date" v-bind="field_props"
	          v-model="data.birthdate"
	          :rules="useRuleNotEmpty"
	          ></v-text-field>
	        </v-col>
	        
	        <v-col cols="6" v-if="storeVars.is_logged_in">
	          <v-combobox label="Civil Status:" :items="['Single', 'Married', 'Widowed']" v-bind="field_props"
	          v-model="data.civil_status"
	          :rules="useRuleNotEmpty"
	          ></v-combobox>
	        </v-col>

	        <v-col cols="6" v-if="storeVars.is_logged_in">
	          <v-combobox label="Sex:" :items="['Male', 'Female']" v-bind="field_props"
	          :rules="useRuleNotEmpty"
	          v-model="data.sex"
	          ></v-combobox>
	        </v-col>               

	      </v-row >

	      <v-divider class="my-5"/>

	      <p class="text-caption mb-3">Address:</p>
	      <v-row >
	        <v-col cols="12" class="no-padding">

	          <v-text-field  label="House / Building / Bock / Street No:" v-bind="field_props"
	          v-model="data.house_street_no"
	          :rules="useRuleNotEmpty"
	          > </v-text-field>
	        </v-col>
	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="Sitio / Purok / Village:" v-bind="field_props"
	          v-model="data.village"
	          :rules="useRuleNotEmpty"
	          > </v-text-field>
	        </v-col>

	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="Barangay:"  v-bind="field_props"
	          v-model="data.barangay"
	          :rules="useRuleNotEmpty"
	          > </v-text-field>
	        </v-col>
	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="City:" v-bind="field_props"
	          v-model="data.city"
	          :rules="useRuleNotEmpty"
	          > </v-text-field>
	        </v-col>
	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="Province:"  v-bind="field_props"
	          v-model="data.province"
	          > </v-text-field>
	        </v-col>
	      </v-row>

	      <v-divider class="my-5"/>

	      <p class="text-caption mb-3">Contacts:</p>

	      <v-row>
	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="Mobile Nos.:"  v-bind="field_props"
	          v-model="data.mobile_nos"
	          :rules="useRuleNotEmpty"
	          > </v-text-field>
	        </v-col>
	        <v-col cols="12" class="no-padding">
	          <v-text-field  label="Land line Nos.:"  v-bind="field_props"
	          v-model="data.phone_nos"
	          > </v-text-field>
	        </v-col>

	        <v-col 
	        	v-if="! storeVars.is_logged_in || storeVars.profile_no_save"
	        	cols="12" class="no-padding">
	          <v-text-field  label="Email address:"  
	          v-bind="field_props"
	          v-model.trim ="data.emails"
	          type="email"
	          :rules="emailRule"	          
	          > </v-text-field>
	        </v-col>

	      </v-row>

	      <v-divider class="my-5"/>

	      <p class="text-caption mb-3">ID Pictures:</p>
	      <v-row
	      	class="mb-2"
	      >
						<!-- <AttachFiles :images="data.images"/> -->
						<v-col
						v-if="! storeVars.profile_no_save"
						>
							<AttachFiles :images="id_pictures" /> 							
						</v-col>

						<v-col
						v-else
						cols=12
						>
							<ImagesChip :images="id_pictures" />
						</v-col>

	      </v-row>

	      <div v-if="! storeVars.is_logged_in">

		      <v-divider class="my-5"/>

		      <p class="text-caption mb-3">Login Credential:</p>
		      <v-row>
		        <v-col cols="12" class="no-padding">
		          <v-text-field  label="Username:"  v-bind="field_props"
		          v-model="data.username"
		          :rules="useRuleNotEmpty"
		          > </v-text-field>
		        </v-col>
		        <v-col cols="12" class="no-padding">
		          <v-text-field  
		          color="black"
		          label="Password:"  
		          v-bind="field_props"
		          v-model="password"
		          :type="pass_input.type"	          
		          :rules="useRulePassword"
		          :append-inner-icon="pass_input.icon"
		          @click:appendInner="pass_input_mode"
		          > 
			          <template v-slot:prepend-inner>
		            	<v-icon :color="color.pass_icon">mdi-key</v-icon>
		          	</template>

			        </v-text-field>
		        </v-col>

		        <v-col cols="12" class="no-padding">
		          <v-text-field  
		          label="Confirm Password:"  
		          v-bind="field_props"
		          v-model="confirm"
		          type="password"
		          :error="confirm_error ? true : false"
		          :error-messages="confirm_error"
		          > 
								<template v-slot:prepend-inner>
	            		<v-icon :color="color.confirm_icon">mdi-key</v-icon>
	          		</template>

		        	</v-text-field>
		        </v-col>

		      </v-row>
	    	</div>

      </v-form>     
    	</v-card-text>

    	<SaveCancel 
    		v-if="! storeVars.profile_no_save"
    		:no_save="storeVars.profile_no_save"				    		
    		:cancel_title="storeVars.is_logged_in ? 'Back' : 'Cancel'"
    		:save_title="storeVars.is_logged_in ? 'Update' : 'Submit'"
    		@save-click="submit" 
    		@cancel-click="router.push( storeVars.is_logged_in ? '/' : '/login' )"
    	/>
    </v-card>

  </div>
</template>

