<script setup>
	/* eslint-disable */
	
	import { ref, onMounted } from 'vue'
	import { useVarsStore } from '@/store/DataStore'

	const storeVars = useVarsStore()

	let scale = 1; // Initial scale
	const step = 0.3
	const zoomIn = () => {
		if ( scale + step > 4 ) return
	  scale += step;	  
	  updateZoom();
	}

	const zoomOut = () => {
		if ( scale - step < 0.2 ) return
	  scale -= step;
	  updateZoom();
	}

	const updateZoom = () => {
	  const image = document.getElementById('img');
	  image.style.transform = `scale(${scale})`;
	}	

</script>

<template>
	
	<v-dialog
    v-model="storeVars.image_viewer.show"
    opacity="0"
  >	  
  	<v-card	class="mx-auto" style="width: 95%" >

  		<div id="imageContainer" class="bg-grey-darken-4"
  			@scroll.up="zoomIn"
  			@scroll.down="zoomOut"
  		>
	 	    <v-img id="img"
		      class="align-end text-white " 
		      :src="storeVars.image_viewer.link"	      
		    >	      
		    </v-img>
	    </div>
		  <div class="bg-grey-darken-4 text-caption px-3 text-white d-flex aling-center"
		  >
		  	<p class="mt-1">{{ storeVars.image_viewer.title}}</p>
		  	<v-spacer></v-spacer>
		  	<v-btn icon="mdi-magnify-minus" variant="text" density="compact" color="primary"
		  		@click="zoomOut"
		  	></v-btn>
		  	<v-btn icon="mdi-magnify-plus" variant="text" density="compact" color="primary"
		  		@click="zoomIn"
		  	></v-btn>	  

		  	<v-btn icon="mdi-close-circle" variant="text" density="compact" color="primary"
		  		@click="storeVars.image_viewer.show = false"
		  	></v-btn>	  

		  </div>		    
<!-- 	    <v-card-text class="bg-grey-lighten-3" align="end">

	    	<v-btn class="" size="small"
	    	@click="storeVars.image_viewer.show = false"
	    	>
	    		Close
	    	</v-btn>
	    </v-card-text>
 -->
	  </v-card>

	</v-dialog>

</template>

<style>
	
.bg-black-01{
	background-color: rgba(0,0,0,.2)
}


#imageContainer {
  /*width: 300px; /* Adjust as needed */
  width: 100%; /* Adjust as needed */
  height: 80%px; /* Adjust as needed */
  /*overflow: hidden;*/
  overflow: auto;
  /*border: 1px solid #ccc;*/

}

#img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

</style>