<script setup>
	/* eslint-disable */

  import { ref, onMounted, defineEmits, defineProps, computed } from 'vue'

  import { useVarsStore, useIncidentsStore } from '@/store/DataStore.js'
  import { useVars, useFuncs } from '@/composables/useFunctions.js'    

  const storeVars = useVarsStore() 
  const storeIncidents = useIncidentsStore()
  
  const props = defineProps(['section'])
  const emits = defineEmits(['get-offline-data'])

  const select = ( type, index ) => {

    let data
    if ( type == "vehicles"){
      data = storeVars.offline_data.qr_vehicles
    }else{  // drivers
      data = storeVars.offline_data.qr_drivers
    }

    emits( 'get-offline-data', type, data[index] )

  }

  const delete_offline_data = ( type, index ) => {

    const delete_item = () => {

      useFuncs.log( type, index )

      const data = useFuncs.local.delete( type , index)

      
      if ( type == 'vehicles'){          
        storeVars.offline_data.qr_vehicles = data
      
      }else{   // drivers
        storeVars.offline_data.qr_drivers = data

      }     

      storeVars.with_offline_data = 
        storeVars.offline_data.qr_drivers.length || 
        storeVars.offline_data.qr_vehicles.length ? true : false      

    }

    useFuncs.ShowMessage( {
      message: "Delete selected item?",
      yes_func: () => {
        delete_item()        
      }
    })
  }

  const show_vehicles = computed(() => {

    const { record } = storeIncidents

    let bln = true
    if ( record.type == useVars.CAT_DriverRatings || 
         record.type == useVars.CAT_DriverComplains ){
      bln = false
    }
    return bln
  })

  // offline data
  const qr_drivers = useFuncs.local.get('drivers')
  const qr_vehicles = useFuncs.local.get('vehicles')

  if ( qr_drivers )   storeVars.offline_data.qr_drivers = qr_drivers
  if ( qr_vehicles )  storeVars.offline_data.qr_vehicles = qr_vehicles

  storeVars.with_offline_data = ( qr_drivers.length || qr_vehicles.lenght ) ? true : false
  

</script>

<template>

  <div 
  v-if="storeVars.with_offline_data"  
  class="mt-2"
  >
    <p class="ml-1 text-subtitle-1">Offline data:</p>
    
    <div
    class="mx-1 pa-2 bg-01 rounded"
    >

      <v-chip 
      v-for="(driver, index) in storeVars.offline_data.qr_drivers"
      :class="`${useVars.offline_colors.qr_driver} ml-1 mb-1 px-5 text-white text-caption py-6`"      
      >
          {{ useFuncs.DateTimeFormat(driver.datetime, 'm/d/Y') }}
          <br>
          {{ driver.profile_name }}
      
        <v-icon 
        v-if="props.section !== 'home'"
        class="ml-3 text-orange-lighten-2 text-h5"
        @click="select('drivers', index)"
        >
          mdi-check-circle
        </v-icon>

        <v-icon 
        class="ml-3 text-red-darken-2 text-h5"
        @click="delete_offline_data('drivers', index)"
        >
          mdi-trash-can
        </v-icon>

      </v-chip>

      <v-chip 
      v-if="show_vehicles"
      v-for="(vehicle, index) in storeVars.offline_data.qr_vehicles"
      :class="`${useVars.offline_colors.qr_vehicle} ml-1 mb-1 px-5 text-white text-caption py-6` "      
      > 
        <div
        class="py-3"
        >
        {{ useFuncs.DateTimeFormat(vehicle.datetime, 'M d, Y') }}<br>
        {{ vehicle.profile_name }}
        </div>
        <br>

        <v-icon 
        v-if="props.section !== 'home'"
        class="ml-3 text-orange-lighten-2 text-h5"
        @click="select('vehicles', index)"
        >
          mdi-check-circle
        </v-icon>

        <v-icon 
        class="ml-3 text-red-darken-2 text-h5"
        @click="delete_offline_data('vehicles', index)"
        >
          mdi-trash-can
        </v-icon>

      </v-chip>
    </div>
  </div>
  
</template>

