
<script setup>
	/* eslint-disable */

	import { ref, computed, watch, onBeforeUpdate, onUpdated } from 'vue'
	import { useVarsStore } from '@/store/DataStore'
	import { useVars, useFuncs } from '@/composables/useFunctions.js'

	import ImagesChip from '@/components/parts/ImagesChip.vue'

	const storeVars = useVarsStore()

	const { bg } = useVars
	const message = ref('')
	const div_msgs = ref(null)

	let disabled_send = true

	// functions

		const send_message = () => {

 			const { prompt_message } = storeVars

      prompt_message.title = "C A U T I O N !"
      prompt_message.message = useVars.submit_text
      prompt_message.yes_caption = "I Agree"
      prompt_message.no_caption = "Abort"
      prompt_message.persistent = true
      prompt_message.color = "bg-blue-grey-darken-4"
      prompt_message.show = true      
      prompt_message.yes_func = () => {

				const p = {fn:'msg+', table: 6,  
					sender_type: useVars.PROFILE_PasaHero,
					sender_id: storeVars.user_profile.id,				
					parent_type: storeVars.post_record.type,
					parent_id: storeVars.post_record.id,
					parent_status: storeVars.post_record.status_no,
					message: message.value,
					last_id: storeVars.chat_last_id(),
					json: 1,
				}

				// remove status field in Driver Rating
				if ( p.parent_type == useVars.CAT_DriverRatings )
					delete p.parent_status

				useFuncs.Post( p, (ret) => {
					
					console.log('submit message', ret)

					message.value = ""

					// add latest messages
	        for( const key in ret.data ){
	          storeVars.post_record.messages.push( ret.data[key] )
	        }			

					setTimeout( () => messages_bottom(), 200);

				})
			}
		}

		const check_latest_messages =  ( hasNew ) => {
      
      if( hasNew )
      	setTimeout( () => messages_bottom(), 200)

      console.log('has new', hasNew)

      if ( ! storeVars.post_record.show ) return                

      setTimeout( () => {
        storeVars.chat_latest( check_latest_messages )
      }, 3000);

    }

		const fullname = (item) => {
			
			let name = item.fullname
			if( item.sender_id == storeVars.user_profile.id) name = "Me"
			return name
		}

		const datetime = (item) =>{
			return useFuncs.DateTimeFormat( item.datetime, "M d, Y")
		}

	// computed

		const cBG = computed(() => {

			let _bg = useFuncs.CustomTypes( useVars.status_types).Color( storeVars.post_record.status_no)
			_bg = "bg-"+ _bg + "-lighten-3"
			return _bg
		})

		const messages = computed(() => {
			const ret = []

			for( const msg of storeVars.post_record.messages ){

				msg.date_time = useFuncs.DateTimeFormat( msg.datetime, "M d, Y")

				if (msg.sender_id == storeVars.user_profile.id) msg.fullname="Me"
				ret.push(msg)
			}

			console.log( 'messages', ret)
			return ret
		})
    
    const messages_bottom = () => {
      if ( div_msgs.value ) div_msgs.value.scrollTo(0, div_msgs.value.scrollHeight)
    }

	// wathcers
		watch( message, (newMessage) => {
			disabled_send = newMessage.length > 10 ? false : true
		})

	// hooks
	 	onBeforeUpdate(() => {
	 		message.value = ""	 		
	 	})

    onUpdated( () => {
      messages_bottom()
      setTimeout( () => check_latest_messages(), 5000)
    })

</script>


<template>
	
	<v-dialog
		v-model="storeVars.post_record.show"
		
		fullscreen
		class="mt-13"
		
	>
		<v-expand-y-transition>


		<v-card 			
			:class="`${cBG} rounded pa-2 w-100`"			
			style="overflow: visible;"
			height="85vh"
		>

<!-- 			<div class="d-flex float-right "
			style="margin-top: -20px; margin-right: -20px;"
			>
				<v-btn 
					class="bg-grey-lighten-4"
					icon="mdi-close"
					density="compact"			
					@click="storeVars.post_record.show = false"
				>				
				</v-btn>
			</div>
 -->
			<v-text>

				<!-- <p class="text-" >{{storeVars.post_record.title}} Report Details:</p> -->
				<div 
					class="pa-2 chat-bg rounded" 
					:style="storeVars.post_record.status_no == useVars.STATUS_Resolved ? 'height:83vh;' : 'height:72vh'"
				>
	
					<div 
						ref="div_msgs"
						class="scroll-y scroll-smooth " 
						:style="storeVars.post_record.status_no == useVars.STATUS_Resolved ? 'height:81vh;' : 'height:70vh'">

						<!-- report entry -->
						<div 							
							class="pa-3 bg-grey-lighten-3"
						>
							<v-row
								no-gutters
								class=""
								align="center"						
								>

								<v-col cols="3" class="text-caption">
									No:
								</v-col>
								<v-col cols="9" class="bg-white rounded-sm pa-1 px-2 text-body-2 ">
									<div class="d-flex">
										{{ storeVars.post_record.no}}
										<v-spacer />
										{{ storeVars.post_record.datetime}}
									</div>
								</v-col>
							</v-row>

							<div 
								v-for="(item, index) in storeVars.post_record.details" :key="index"
								class="w-100"					
								>
								
								<v-row
									v-if="item.value" 
									no-gutters
									class="my-1"
									align="center"						
								>
									<v-col cols="3" class="text-caption">
										{{ item.title }}:
									</v-col>
								
									<v-col cols="9" class="bg-white rounded-sm pa-1 text-body-2 ">
										{{item.value}}
									</v-col>
								</v-row>

							</div>

							<v-row
								no-gutters
								class=""
								align="center"						
								>

								<v-col cols="3" class="text-caption">
									Images:
								</v-col>
								<v-col cols="9" class="mt-1">
									<ImagesChip :images="storeVars.post_record.images"/>
								</v-col>
							</v-row>							

						</div>

						<!-- Messages -->

						<div v-if="storeVars.post_record.messages"						
							class="pa-2"			
						>
							<v-row
								v-for="(item, index) in storeVars.post_record.messages" :key="index"
								no-gutters
								>

								<v-col 
									v-if="item.sender_id != storeVars.user_profile.id"
		              cols="auto" 
		              >

		              <v-card 
		                class="mb-3 py-2 px-4 rounded-xl"
		                max-width="350"
		              >
		                
										<div class="d-flex align-center">										
											<p class="text-caption text-primary">{{ fullname(item) }} </p>
											<p class="text-caption text-grey font-italic">
												&nbsp;:&nbsp;{{ datetime(item) }}
											</p>
										</div>
										<div class="text-body-2 text-grey-darken-3">
											{{item.message}}
										</div>
		              </v-card>
		            </v-col>								

								<v-spacer></v-spacer>

								<v-col cols="auto" 
									v-if="item.sender_id == storeVars.user_profile.id"									
									>

 		              <v-card 
		                class="mb-3 py-2 px-4 rounded-xl  bg-yellow-lighten-5"
		                max-width="350"
		              >
		                
										<div class="d-flex align-center">										
											<p class="text-caption text-primary">{{ fullname(item) }} </p>
											<p class="text-caption text-grey font-italic">
												&nbsp;:&nbsp;{{ datetime(item) }}
											</p>
										</div>
										<div class="text-body-2 text-grey-darken-3">
											{{item.message}}
										</div>
		              </v-card>

								</v-col>

							</v-row>
						</div>							
					</div>

				</div>

				<div 
					v-if="storeVars.post_record.status_no != useVars.STATUS_Resolved"
					class="bg-grey-lighten-4 rounded-lg mt-1 d-flex align-center"
				>
					<v-textarea 
						v-model="message"
						v-bind="useVars.field_props"
						class="text-body-2 pa-1"
						rows="3"
						clearable
					>							
					</v-textarea>
					<v-btn
						icon="mdi-send"
						class="bg-primary mr-1"						
						density="comfortable"
						@click="send_message"
						:disabled="disabled_send"
						elevation="4"
						>
					</v-btn>
				</div>

			</v-text>
		</v-card>
		</v-expand-y-transition>

	</v-dialog>
  

</template>

<style>
	.chat-bg{
	  background-image: linear-gradient(to bottom, white, rgba(0,200,255,0.5));
	}
	.scroll-smooth{ scroll-behavior: smooth }
</style>