

<script setup>
	/* eslint-disable */

	import { ref, defineEmits, onMounted } from 'vue';
	import { useRoute, useRouter } from 'vue-router';

	import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode";

	import SaveCancel from '@/components/parts/SaveCancel.vue'
	import { useVars, useFuncs } from '@/composables/useFunctions.js';
	import { useVarsStore } from '@/store/DataStore.js'

  const router = useRouter()
  const route = useRoute()
	const capture_qr = ref(null)	

	const storeVars = useVarsStore()	

	const emits = defineEmits(['accept-click'])

	const data = ref({
		dialog: false, license_no: '', name: '', mobile_no:''
	})

	let html5QrCode;

	const accept = () => {

		storeVars.qr_data.license_no = data.value.license_no
		storeVars.qr_data.name = data.value.name
		storeVars.qr_data.mobile_no = data.value.mobile_no
		
		emits("accept-click")

		data.value.dialog = false
		
	}

	const done_capture = () => {
		
		  if (capture_qr.value.files.length == 0) {
		  	// no files selected
		    return
		  }

		  const imageFile = capture_qr.value.files[0];
		  
		  // Scan QR Code
		  html5QrCode.scanFile(imageFile, true)
		  .then(decodedText => {

		    // success, use decodedText
		    // console.log(decodedText);

		    const lines = decodedText.split("\r\n")
		    if ( lines.length >= 3 ){

		    	data.value.license_no = lines[0]
		    	data.value.name = lines[1]
		    	data.value.mobile_no = lines[2]
		    	data.value.dialog = true

		    }else{

		    }

		  })
		  .catch(err => {
		  		  
        storeVars.snackbar_message("No data found in scanned image.")

		  });		

	}

  onMounted(() => {  	
  	
  	// console.log('current', storeVars.qr_data )
  	useFuncs.ResetObject( storeVars.qr_data, false);
  	// console.log('cleared', storeVars.qr_data )

  	html5QrCode = new Html5Qrcode(/* element id */ "reader");  	  	

    if ( route.params.qr == "qr" ){
      capture_qr.value.click()
    }

  });

 	const scan_qr_code = () => {
    capture_qr.value.value = null
 		capture_qr.value.click()
 	}
  
</script>

<template>  

  <v-icon 
  	class="text-h4"
    @click="scan_qr_code"
  >mdi-qrcode-scan</v-icon>            

	<div hidden id="reader" width="200px"></div>
	<input hidden ref="capture_qr" type="file" id="qr-input-file" 
		accept="image/*" capture
		@change="done_capture"
	>


  <v-dialog
    v-model="data.dialog"
    width="400"
    persistent
  >
    <v-card
      max-width="400"
      prepend-icon="mdi-qrcode-scan"      
      title="Detected QR Values"
    >

    	<v-card-text class="ma-3 mb-3">
    		<v-col cols="12">
    		<v-text-field    		
    			v-model="data.license_no"
    			label="License No.:"
    			v-bind="useVars.field_props"
    		>    	
    		</v-text-field>
    		</v-col>		

    		<v-col cols="12">    		
    		<v-text-field
    			v-model="data.name"
    			label="Driver's Name:"
    			v-bind="useVars.field_props"
    		>    			    		
    		</v-text-field>
    		</v-col>

    		<v-col cols="12">
    		<v-text-field
    			v-model="data.mobile_no"
    			label="Mobile No.:"
    			v-bind="useVars.field_props"
    		>    			
    		</v-text-field>
    		</v-col>
    	</v-card-text>
      
      <SaveCancel 
        :can_delete="false" 
        save_title="Accept"
        cancel_title="Cancel"
        @save-click="accept" 
        @cancel-click="data.dialog = false"        
      />

    </v-card>
  </v-dialog>

	
</template>