import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import vue3GoogleLogin from 'vue3-google-login'
import './registerServiceWorker'

const pinia = createPinia();
loadFonts()

const gAuthOptions = { clientId: '821502301343-mbhrllkiss0g23anqbdhnn4qhc01n11m.apps.googleusercontent.com'} //, scope: 'email', prompt: 'consent', fetch_basic_profile: false }


createApp(App)
  .use(router)
  .use(vuetify)
  .use(pinia)
  .use(vue3GoogleLogin, gAuthOptions)
  .mount('#app')
