/* eslint-disable */

import { defineStore } from 'pinia'
import { useFuncs, useVars } from '@/composables/useFunctions.js';


export const useVarsStore = defineStore('variablesStore', 
{  
  state: () => ({
    
    build_version: 'build 24.1012.2',
    topRightIcon: '',
    app_drawer: false,

    view_bg: "bg-grey-lighten-2",

    show_busy: false, 
    show_setting: false,    
    
    is_mobile: false,

    message: { show: false, text: '', msecs: 10000, color: '', btn_caption: 'Ok' },

    att_files: [],
    att_file_index : 0,
    att_files_for_delete: [],

    logs: '',

    prompt_message: { show: false, title: '', message: '', 
      color: '', yes_caption: 'Ok', no_caption: 'Close', 
      persistent: false,
      yes_func: () => {} },

    image_url: '',

    qr_data: {license_no: '', name: '', mobile_no: ''},

    is_logged_in: false,
    user_profile: {},
    profile_no_save: false,

    connection : { is_online: true, is_checking: false, offline_mode: false },

    user_features: [1,2,3,4,5],
    clone: { user_features: [] },
    
    my_entries_count: [],

    post_record: {data: [], images: [], show: false, id: -1, type: -1},

    image_viewer: {link: '', title: "", show: false },

    search_show: false ,
    searched_profile: {name: '', id: ''},

    offline_data :{ qr_drivers: [], qr_vehicles: [] },
    with_offline_data: false,

  }),

  actions:{

    // ==========
    //    chat
    // ==========

      PostRecord( type, id ){

        this.show_busy = true;

        const { CAT_Incidents, CAT_LeftItems, CAT_DriverComplains, 
          CAT_DriverRatings, CAT_SafetySelfies } = useVars

        let fields = "", table = 3
        
        if ( type == CAT_Incidents ){
          fields = 'incident_fields'

        }else if( type == CAT_LeftItems ){
          fields = 'left-item-fields'

        }else if( type == CAT_DriverComplains){
          fields = "complain-fields"
          table = 5

        }else if( type == CAT_DriverRatings ){
          fields = "rating-fields"
          table = 5
          
        }else if( type == CAT_SafetySelfies){
          fields = "selfie-fields"
          table = 5

        }
        
        this.post_record.ready_data = false
        this.post_record.ready_messages = false

        // clear
        this.post_record.data = []
        this.post_record.images = []
        this.post_record.messages = []

        // data
        let p = { fn: 'fetch', table: table, one: 1, fields: fields,        
          rec_id_cond: id, json: 1
        }

        useFuncs.Post( p, (ret) => {

          // console.log( 'post record',ret)
          const data = ret.data

          let type_name = "", event_datetime = "", offender = "", driver = ""

          if ( type == CAT_Incidents ){
            type_name = useFuncs.CustomTypes( useVars.incident_types ).Title(data.incident_type)
         
          }else if( type == CAT_LeftItems){
            event_datetime = useFuncs.DateTimeFormat( data.datetime_event, "M d, Y")
          
          }else if( type == CAT_DriverComplains ){
            type_name = useFuncs.CustomTypes( useVars.driver_complain_types ).Title(data.comment_type)         
            offender = data.profile_name

          }else if( type == CAT_DriverRatings){
            driver = data.profile_name

          }

          const status = useFuncs.CustomTypes( useVars.status_types ).Title(data.status)         
          const title = useFuncs.CustomTypes( useVars.category_types ).Title(type)         

          const record = [
            {title: 'Status', value: status },
            {title: 'Incident Type', value: type_name },
            {title: 'When', value: event_datetime},
            {title: 'Offender', value: offender },
            {title: 'Driver', value: driver },
            {title: 'Location', value: data.location },
            {title: 'Description', value: data.description },          
          ]


          this.post_record.title = title
          this.post_record.no = useFuncs.TrackingNo(type, id)
          this.post_record.status_no = data.status

          this.post_record.datetime = useFuncs.DateTimeFormat( data.datetime, "M d, Y")

          this.post_record.details = record
          this.post_record.images = ret.images
          this.post_record.ready_data = true;
          this.post_record.id = id
          this.post_record.type = type

          this.PostRecord_Show()
        })

        this.PostRecord_Messages( type, id, (ret) => {
          if ( ret.data) this.post_record.messages = ret.data
          this.post_record.ready_messages = true
          this.PostRecord_Show()
        })

      },

      PostRecord_Messages( type, id, callback ){

        // messages
        const p = { fn: 'msgs', type: type, id: id, json:1 }
        useFuncs.Post( p, (ret) => {
          // console.log( 'messages', ret)        
          callback(ret);
        })

      },

      PostRecord_Show(){

        if ( this.post_record.ready_data && this.post_record.ready_messages){
          this.show_busy = false
          this.post_record.show = true
        }

      },

      chat_last_id(){

        let last_id = 0
        if ( this.post_record.messages.length ){
          const last = this.post_record.messages.at(-1)
          last_id = last.id
        }
        return last_id
      },

      chat_latest( callback ){

        const p = {fn:'msgs+++', table: 6,  
          parent_type: this.post_record.type,
          parent_id: this.post_record.id,
          last_id: this.chat_last_id(), 
          json:1,    
        }
        useFuncs.Post( p, (ret) => {
          
          let hasNew = false

          if ( ret.data ){
            for( const key in ret.data ){
              this.post_record.messages.push( ret.data[key] )
            }
            hasNew = true
          }
          callback( hasNew )
        })      
      },

    // =====================

    EntryCounts(){      
      useFuncs.Post( {fn: 'n3cnt', id: this.user_profile.id, json: 1}, (ret) => {
        
        // console.log('entries counter',ret)
        this.my_entries_count = ret.data
      })
    },

    NavBack( isShow = true ){      
      this.topRightIcon = isShow ? "mdi-arrow-left" : ""
    },

    snackbar_message( text, color="", secs = 5, btn_caption ="Ok"){
      this.message.text = text.split("<br>")
      this.message.color = color
      this.message.btn_caption = btn_caption
      if ( secs ) this.message.msecs = secs * 1000

      this.message.show = true
    },

    IsActive( type ){
      //console.log( 'features', this.user_features)
      return this.user_features.includes(type)
    },

  }
})

export const useIncidentsStore = defineStore('incidentsStore', {

  state: () => ({

    record: {type: 0, is_add: true, is_show: false, id: 0, title:'', note:''},

    incidents: [],    
    left_items: [],
    driver_ratings:[],
    driver_complains:[],

    // dashboard filter
    date_from: useFuncs.DateFormat( Date.now(), "Y-m-d" ),
    date_to: useFuncs.DateFormat( Date.now(), "Y-m-d" ),   
    top_num: 3,
    is_all: true,


  }),

  actions: {

    Add( category_type ){
            
      this.record = {
        type: category_type,
        id: 0,
        is_add: true,
        is_show: true,        
      }

    },

    Edit( category_type, id){

      this.record = {
        type: category_type,
        id: id,
        is_add: false,
        is_show: true,        
      }
    },

    Close(){
      this.record = {
        type: 0,
        id: 0,
        is_add: false,
        is_show: false,        
      }
    }
  }

})

/*
export const useVehicleStore = defineStore('vehicleStore', {

  state: () => ({
    records: [],
    records_copy: [],
  }),

  getters: {

  },
  actions: {   
  }

})

export const useProfileStore = defineStore('driverStore', 
{  


  state: () => ({
    records: [],
    records_copy: [],
  }),
  getters:{

  },

  actions:{

    deleteDriver( ids = [] ){      
      this.records = this.records.filter( (r) => {
        return  ! ids.includes( r.id )
      })

      // delete in database
      const p = {fn: 'remove', table: 1, ids: ids.join() }
      useFuncs.Post(p, (res) => {
        console.log('res', res)
      } )
    },
  }

})

*/