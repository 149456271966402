
<script setup>
  /* eslint-disable */

  import { ref, computed } from 'vue'
  import { useVarsStore, useIncidentsStore } from '@/store/DataStore.js'
  import { useFuncs } from '@/composables/useFunctions.js'

  import { useRouter } from 'vue-router';

  const router = useRouter()

  const storeVars = useVarsStore()
  const storeIncidents = useIncidentsStore()
  const show_build = ref(false)

  const back_home = () => {
    storeVars.topRightIcon = ""
    storeVars.view_bg = "bg-blue-grey-lighten-4"
    router.push("/")     
  }

  const logout = () => {

    const {last, first} = storeVars.user_profile
    const name = last + ", " + first
    storeVars.snackbar_message("Thank you for using PasaHero App. See you soon " + name + ".")
    
    storeVars.is_logged_in = false
    storeVars.app_drawer = false
    storeVars.user_profile = {}
    
    router.push("/login")


  }

  const re_login = () => {
    
    const url = useFuncs.BaseURL()        
    window.location.replace(url)

  }

  // back to home if no record type selected
  if ( storeIncidents.record.type == 0){
    back_home()
  }

  const editProfile = (is_no_save = false) => {
    storeVars.profile_no_save = is_no_save
    storeVars.NavBack()
    router.push('/registration')
  }

  const avatar_num = computed( () => {
     return Math.floor(Math.random() * 50) + 1;
  })
</script>


<template>
  
    <v-app-bar
      color="primary"
      prominent
      density="compact"
    >
      <v-app-bar-nav-icon 
        v-if="storeVars.is_logged_in"
        variant="text" 
        @click.stop="storeVars.app_drawer = !storeVars.app_drawer">
      </v-app-bar-nav-icon>  
      

      <div 
        class="text-h5 cursor-pointer" 
        @click="back_home"
      > 
        <span
          v-if="!storeVars.is_logged_in"
        >&nbsp;&nbsp;         
        </span>
        
        <span class="text-blue-lighten-3">Pasa</span>
        
        <span 
          @click="show_build = !show_build"
          class="font-weight-black text-orange-lighten-1 text-h5"
          :title="storeVars.build_version"
        >Hero</span>

        <span
        v-if="show_build"
        class="text-caption mx-2 text-blue"
        >
         {{ storeVars.build_version }}
        </span>
      </div>
      
      <v-spacer></v-spacer>    

      <v-slide-x-reverse-transition>
        <v-btn 
          v-if="storeVars.topRightIcon"
          :icon="storeVars.topRightIcon" 
          color="white"           
          class="text-h6"        
          variant="compact"
          @click="back_home"
        >      
        </v-btn>      
      </v-slide-x-reverse-transition>

      <v-avatar
        v-if="storeVars.is_logged_in"
        color="grey"        
        size="40"
        class="mx-2 border-primary border-sm"
        @click.stop="editProfile(true)"
      >
        <v-img 
          :src="storeVars.user_profile.avatar" cover></v-img>
      </v-avatar>

      <div
      v-else
      >
        <v-chip        
          class="mr-2 pr-4 text-orange-lighten-3 "
          @click="re_login" 
        >
        <v-icon class="text-orange mr-2">mdi-arrow-left-circle</v-icon>
        Retry Login
        </v-chip>

      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-model="storeVars.app_drawer"      
      temporary
    >

      <div class="d-flex flex-column h-100">
      <v-list>
        <v-list-item
        >
          <p class="text-h6">{{storeVars.user_profile.name}}</p>
          <p class="text-subtitle-2">{{storeVars.user_profile.emails}}</p>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list density="compact" nav>
        <v-list-item 
          prepend-icon="mdi-account" 
          title="Update My Profile" 
          value="profile"
          @click="editProfile(false)"
        ></v-list-item>
        
        <v-list-item 
          prepend-icon="mdi-cog" 
          title="Settings" 
          value="setting"
          @click="storeVars.show_setting = true"
        ></v-list-item>      

      </v-list>

      <v-spacer></v-spacer>
      
      <v-btn
      class="ma-2 aligh-self-end"
      color="blue-grey-darken-2"
      @click="logout"
      >LOGOUT</v-btn>      
      </div>


    </v-navigation-drawer>

</template>

