<script setup>
	/* eslint-disable */

  import { ref, computed, defineProps } from 'vue'

  import { useVarsStore } from '@/store/DataStore.js'
  import { useVars, useFuncs } from '@/composables/useFunctions.js'  

  const props = defineProps(['type'])

  const storeVars = useVarsStore()

  const {
    CAT_Incidents,      CAT_LeftItems,      CAT_DriverComplains, 
    CAT_DriverRatings,  CAT_SafetySelfies,  CAT_FareMatrix,  
    status_types,
  } = useVars

	
  const counter = computed(() => {

  	const ret = []
  	for( const item of Object.values( storeVars.my_entries_count)){

  		// console.log('item entries', item)
  		if ( item.data_type == props.type ){

  			if ( props.type == CAT_DriverRatings ){
  				item.color = "grey"
  			}else{
  				item.color = useFuncs.CustomTypes(status_types).Color( item.status )
  			}
  			ret.push(item)
  		}
  	}

  	// console.log( 'counters',ret)
  	return ret
  })

</script>

<template>

  <v-chip v-for="item in counter" :key="item.status"
  	:color="item.color" 
  	variant="elevated"
  	size="x-small" 
  	class="ml-1" 	
  >
  <p class="text-white">{{ item.cnt }}</p>
	</v-chip>

</template>