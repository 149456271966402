<script setup>
  /* eslint-disable */

  import { defineProps, defineEmits} from 'vue'
  import { useVarsStore } from '@/store/DataStore.js'

  const storeVars = useVarsStore()
  
  // const emit = defineEmits(['yesClick'])

  const yesClick = () => {
    storeVars.prompt_message.yes_func()
    storeVars.prompt_message.show = false

  }

</script>

<template>
  
  <v-dialog 
  max-width="400"
  v-model="storeVars.prompt_message.show"
  :persistent="storeVars.prompt_message.persistent"
  >
    <v-card 
    :title="storeVars.prompt_message.title" 
    :class="storeVars.prompt_message.color"
    >
    
      <v-card-text class="text-justify my-5">
        {{ storeVars.prompt_message.message }}        
      </v-card-text>
      
      <div class="d-flex pt-3 pb-3 pr-5">
        
        <v-spacer></v-spacer>

        <v-btn
          :text="storeVars.prompt_message.yes_caption" density="comfortable"
          @click="yesClick"          
          prepend-icon="mdi-check-circle"
          class="mr-2 text-black"
        >          
          <template v-slot:prepend>
            <v-icon color="success"></v-icon>
          </template>
        </v-btn>

        <v-btn
          v-if="storeVars.prompt_message.no_caption != '-'"
          class="text-black" :text="storeVars.prompt_message.no_caption" density="comfortable"
          @click="storeVars.prompt_message.show = false"
          prepend-icon="mdi-close-circle"          
        >
          <template v-slot:prepend>
            <v-icon color="red"></v-icon>
          </template>
        </v-btn>
      </div>

    </v-card>
  </v-dialog>

</template>

