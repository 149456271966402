<script setup>
	/* eslint-disable */

	import { ref, defineProps, onMounted } from 'vue'
	import { useVarsStore } from '@/store/DataStore'
	import { useVars, useFuncs } from '@/composables/useFunctions.js'

	const storeVars = useVarsStore()

	const props = defineProps({
		accept: {type: String, default: "image/png, image/jpeg"}, 
		label: 	{type: String, default : "Attach Image Files (2MB max per file)"},
		images: false,
		})
	

	const cam_pic = ref(null)
	const get_files = ref(null)

	// hooks
		onMounted( () => {
			storeVars.att_files = []
			storeVars.att_file_index = 0
		})

	  const cam_capture = (type) => {

	  	console.log( 'type', type)
	  	if ( type == "cam"){
	  		cam_pic.value.click()
	  	}else{
	  		get_files.value.click()
	  	}

	  }

	  function collect_file(type){

	  	let files = []
	  	if ( type == "cam"){
	  		files = cam_pic.value.files
	  	}else{
	  		files = get_files.value.files
	  	}
	  
	  	Object.values(files).forEach( ( file ) => {
	  		
	  		storeVars.show_busy = true

	  		useFuncs.ResizeImageQuality(file, 500, (blob) => {

					if ( blob !== null ){						

						let name = file.name
						if ( type == "cam" ){							
							name = useFuncs.PicFileName( name )						
						}

						const _file = new File( [blob], name, { type: file.type } )
						storeVars.att_files.push( _file )												  		

					}else{
						storeVars.att_files.push( file )
					}					
					
					storeVars.show_busy = false		
				})		  		
	  	})  	

	  	
	  }

</script>

<template>
	
	<input ref="cam_pic" capture="camera" type="file" multiple accept="image/*"  
		id="pic"
		hidden @change="collect_file('cam')">

	<input ref="get_files" type="file" multiple accept="image/*" hidden 
		id="files"
		@change="collect_file('files')">

	<div class="d-flex align-center">
		
		<v-btn 	
			icon="mdi-camera" 
			@click="cam_capture('cam')"
			density="comfortable"
		></v-btn>

		<v-file-input
			v-bind="useVars.field_props"
		  v-model="storeVars.att_files"
		  :label="props.label"
		  multiple
		  show-size="1000"
		  counter	  
		  :accept="props.accept"
		  hide-details	 	  
		  readonly
		  prepend-icon=""
		  class="mx-2"
	  >  
	    <template v-slot:selection="{ fileNames }">
	      <template v-for="(fileName, index) in fileNames" :key="fileName">
	        <v-chip
	          class="me-2"
	          color="primary"
	          size="small"
	          label          
	        >
	          {{ fileName }}
	        </v-chip>
	      </template>
	    </template>
	  </v-file-input>

		<v-btn 
			icon="mdi-image"
			@click="cam_capture('files')"
			density="comfortable"
		></v-btn>
	</div>


  <div v-if="props.images" 
  class='d-flex flex-wrap ga-1 mt-2'
  >
      <v-chip size="small" color ="primary"   class=""
      v-for="(img, index) in images" :key="img.id"              
      @click="useFuncs.ViewImage( img )"
      closable
      @click:close="useFuncs.AttForDelete( img.id )"
      >
        {{ img.file_name}}                
      </v-chip>          
  </div>



</template>