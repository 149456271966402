<script setup>
	/* eslint-disable */

	import { ref, onMounted }	from 'vue'
  import { useRouter } from 'vue-router';

	import { useVarsStore } from '@/store/DataStore'
	import { useVars, useFuncs } from '@/composables/useFunctions.js'

  import { decodeCredential  } from "vue3-google-login"

  const router = useRouter()

	const storeVars = useVarsStore()

  const {CAT_Incidents, CAT_LeftItems, CAT_DriverComplains, 
    CAT_DriverRatings, CAT_SafetySelfies, CAT_FareMatrix, field_props } = useVars

	const bgs = {
	  [CAT_Incidents]: 'red-darken-4',          
    [CAT_LeftItems]: 'green-darken-4',
    [CAT_DriverComplains]: 'blue-darken-4',
    [CAT_DriverRatings]: 'orange-darken-4',
    [CAT_SafetySelfies]: 'purple-darken-4',
    [CAT_FareMatrix]: 'lime-darken-4',
	}

  storeVars.view_bg = "bg-primary" 
	
	const rnd = Math.floor(Math.random() * 6) + 1	
  const bg = 'bg-' + bgs[rnd] 
  
  const user = ref('')  
  const pass = ref('')
  const e_user = ref(null)

  const forgot_pass = () => {

  }

  const login_success = ( ret ) => {

    storeVars.is_logged_in = true      
    storeVars.snackbar_message(`Hi ${ret.data.last}, ${ret.data.first}. Welcome to PasaHero App`, "", 10)

    storeVars.user_profile = ret.data
    storeVars.user_profile.is_google_login = ret.is_google_login

    const {last, first, middle } = ret.data
    let name = `${last}, ${first}`     
    if ( middle ) name +=  ` ${middle.charAt(0)}.`

    if ( ret.is_google_login ){
      
      console.log( 'avatar', ret.avatar_url)

      storeVars.user_profile.avatar = ret.avatar_url
      name = ret.name

    }else{
    
      if( ret.images ){

        const avatar_id = ret.images[0].id
        storeVars.user_profile.avatar_id = avatar_id 

        useFuncs.SetAvatar()
      }
    }

    storeVars.user_profile.name = useFuncs.ProperCase(name);

    // user settings
    useFuncs.Post( {fn: 'gs', t: 'USERPREF', d:'Features', c: storeVars.user_profile.id,
    xp:'t,d,c', rf: "s", json:1 }, 
    (ret) => {        
      
      // console.log( "user features", ret)       

      if ( ! ret.data ){
        // no settings yet for new users
        ret.data = "1,2,3,4,5"
      }
      const codes = ret.data.split(",").map( str => parseInt(str));

      storeVars.user_features = [...codes]
      storeVars.clone.user_features = [...codes] 
      
      storeVars.show_busy = false
      router.push("/")    
    })            

  }

  const login = () => {

    if ( ! storeVars.connection.is_online ){

      storeVars.connection.offline_mode = true;
      router.push("/")    

    }else{

      if ( ! user.value.length || !pass.value.length ) return

      storeVars.show_busy = true;

      const p = {fn: 'ln', u: user.value, p: pass.value, json: 1 }

      useFuncs.Post( p, ( ret ) => {
      
        // console.log('ret', ret)
        storeVars.show_busy = false

        if ( ret.status == 'failed'){
          
          storeVars.snackbar_message("Access Denied! Invalid login credential.", "red")
          e_user.value.focus()
          return

        }else if (ret.status == 'offline'){
          
          storeVars.snackbar_message("Unable to reach dataservice. Please try again later")

          useFuncs.ShowMessage({
            message: "Your device is offline. Would you like to go offline mode?",
            color: 'bg-grey-darken-2',
            persistent: true,
            yes_func: () => {

              storeVars.connection.offline_mode = true;
              router.push("/")    
            }
          })

          return;
        }

        ret.is_google_login = false
        login_success( ret );

      })

    }
  }

  const g_login = (res) => {

    // console.log('google res', res)

    const userData = decodeCredential(res.credential)
    // console.log('user data', userData)
    
    if ( userData ){

      const { family_name, given_name, name, email, picture } = userData

      const p = {fn: 'g-ln',lname: family_name, fname: given_name, e: email, json: 1}

      useFuncs.Post( p, (ret) => {        

        console.log( 'g login', ret)

        ret.is_google_login = true
        ret.avatar_url = picture
        ret.name = name

        login_success( ret );
      })
    }
  }
  
  const show_version = () => {
    storeVars.snackbar_message("version: "+ storeVars.build_version,'', 2)    
  } 

  onMounted(() => {

    if ( useFuncs.DEV_MODE() ){
      user.value = "aaa"
      pass.value = "111"
    }    
    
  })

</script>

<template> 

    <div  :class="`w-100 ${bg}`" >
      <div class="text-h3 cursor-pointer bg-primary d-flex align-end justify-center"      
      > 
        <span class="text-blue-lighten-3 " style="margin-bottom: -20px;">Pasa</span>
        <span class="font-weight-black text-orange-lighten-1 " 
          style="margin-bottom: -20px;"
          @click="show_version"
        >Hero</span>        
      </div>

      <v-container  
        class="d-flex align-center justify-center py-10" >
        <v-card           
          width="300" class="opacity-90 py-3"
        >

          <div
            v-if="storeVars.connection.is_online"
          >
            <v-card-text>

              <v-row class="px-2">
                <v-col >
                  <v-text-field
                  ref="e_user"
                  v-bind="useVars.field_props"
                  label="Username / email:"
                  v-model.trim="user"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col>
                  <v-text-field 
                  v-bind="useVars.field_props"
                  label="Password:"
                  v-model.trim="pass"
                  type="password"  
                  @keydown.enter="login"              
                  >
                  </v-text-field>
                  <p class="text-caption text-right mt-2 cursor-pointer"
                  @click="forgot_pass"
                  >Forgot password?</p>
                </v-col>
              </v-row>

              <v-row justify="center">             
                
                <v-btn                   
                  density="comfortable"
                  color="green-darken-2"
                  @click="login"
                  width="250"
                  > 
                  Login
                </v-btn>

                <div 
                  class="my-2 d-flex align-center ga-2"
                >
                  <p class="text-caption">No PasaHero account yet?</p>
                  <p                     
                    class="text-subtitle-2 text-blue cursor-pointer"
                    @click="router.push('/registration')"
                  >
                    Sign Up
                  </p>                  
                </div>

              </v-row>

              <div
              >
             
                <hr class="mx-2" color="silver" style="margin-top: 30px;"></hr>
               
                <div style="margin-top: -10px; z-index: 99;" class="d-flex justify-center mb-3 text-caption">
                  <p class="bg-white px-1">OR</p>
                </div>

                <v-row>
                  <v-col                   
                    cols="12" class="d-flex justify-center">             
                    <GoogleLogin :callback="g_login" :prompt="false" :autoLogin="true"/>
                  </v-col>
                </v-row>
              </div>

            </v-card-text>
          </div>

          <div
            v-else
          >
            <center class="pa-5">
            <v-btn                   
              density="comfortable"
              color="grey-darken-3"
              @click="login"
              width="250"
              height="50"
              class="my-5"
              > 
              Continue Offline Mode
            </v-btn>
            </center>
          </div>

        </v-card>
      </v-container>
    </div> 

  
    
</template>

<style >
  
  .opacity-50{
    opacity: 50%;
  }
</style>