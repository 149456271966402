<script setup >
		/* eslint-disable */

	import { onMounted, defineProps } from 'vue'
	import { useFuncs } from '@/composables/useFunctions.js'  
	import { useVarsStore } from '@/store/DataStore.js'

	const storeVars = useVarsStore()

	const props = defineProps({ 			
			color: {type: String, default: 'blue-lighten-1' },
			images : false, 
	})
	


</script>

<template>
	
	<div 
		v-if="props.images"
		class="d-flex"
		>
		
		<v-chip size="x-small" :color="props.color" variant="elevated"   
		class="mr-1"
		v-for="(img,index) in props.images" :key="img.id"
		@click="useFuncs.ViewImage( img )"
		>
			img-{{index + 1}}
		</v-chip>
	</div>

</template>