
<script setup >
  /* eslint-disable */

  import { ref, computed, onMounted } from 'vue'
  import { useRouter } from 'vue-router';

  import { useVarsStore, useIncidentsStore } from '@/store/DataStore.js'
  import { useVars, useFuncs } from '@/composables/useFunctions.js'  
  
  import ReadQRCode from '@/components/parts/ReadQRCode.vue'
  import EntriesCounter from '@/components/EntriesCounter.vue'  
  import OfflineData from '@/components/OfflineData.vue'  

  const storeVars = useVarsStore()

  const storeIncidents = useIncidentsStore()
  const router = useRouter();  

  const show_snackbar = ref(false)
  const camera = ref(null)
  const dl_file = ref(null)
  
  let capture_msg = true



  const {
    CAT_Incidents,      CAT_LeftItems,      CAT_DriverComplains, 
    CAT_DriverRatings,  CAT_SafetySelfies,  CAT_FareMatrix,
    field_props, bg
  } = useVars


  const titles = {
    [CAT_Incidents]: 'Report an Incident',
    [CAT_LeftItems]: 'Report Lost / Missing Item',
    [CAT_DriverComplains]: 'Complain a Driver',
    [CAT_DriverRatings]: 'Comment & Rate a Driver',
    [CAT_SafetySelfies]: 'Safety Ride Selfie',
    [CAT_FareMatrix]: 'Current Fare Matrix'
  }

  const notes = {
    [CAT_Incidents]: '',
    [CAT_LeftItems]: '',
    [CAT_DriverComplains]: '',
    [CAT_DriverRatings]: '',
    [CAT_SafetySelfies]: 'Please include body/plate no. in the selfie.',
    [CAT_FareMatrix]: 'Current Fare Matrix'
  }

  let qr_clicked = false;

  const view_to = ( type ) => {

    // hide snackbar message
    storeVars.message.show = false

    storeVars.NavBack()
    storeVars.view_bg = "bg-" + bg[type].replace("darken","lighten")

    storeIncidents.record.is_add = true    
    storeIncidents.record.title = titles[ type ]
    storeIncidents.record.note = notes[ type ]

    if ( type == CAT_Incidents ){

      storeIncidents.record.type = CAT_Incidents
      router.push("/incident")

    }else if ( type == CAT_LeftItems ){

      storeIncidents.record.type = CAT_LeftItems
      router.push("/incident")

    }else if ( type == CAT_DriverComplains ){

      storeIncidents.record.type = CAT_DriverComplains

      let p = ""
      if ( qr_clicked ) p = "/qr"
      router.push("/incident" + p)

    }else if ( type == CAT_DriverRatings ){

      storeIncidents.record.type = CAT_DriverRatings

      let p = ""
      if ( qr_clicked ) p = "/qr"
      router.push("/incident" + p)

    }else if( type == CAT_SafetySelfies ){

      router.push('/selfie')
  
    }else if( type == CAT_FareMatrix ){

      router.push('/fare-matrix')
    }
  
  }  

  const scan_qr_code = () => {
    qr_clicked = true
  }

  // ==================
  // offline mode functions
  // ==================

    const take_picture = () => {

      const func = () => {      
        camera.value.value = ""
        camera.value.click()
      }

      if ( capture_msg ){

        capture_msg = false;  

        useFuncs.ShowMessage({
          message: "Captured images will be saved in your desired storage folder. " +
            "You can use this saved images in submitting reports later.",
          color: 'bg-grey-darken-2',
          yes_caption: 'Ok',
          no_caption: '-',        
          persistent: true,
          yes_func: func,
        })        
        return
      }
      func()     

    }

    const save_image_locally = () =>{
     
      // resize pic       
      const file = camera.value.files[0]
      if ( file ){

        useFuncs.log( file.name )
        const ext = file.name.split('.').pop()

        const dttm = useFuncs.DateTimeFormat( new Date(), "Y-m-d", "_h-m-s ap")
        // save as file
        const url = URL.createObjectURL(file);
        dl_file.value.href = url
        dl_file.value.download = "pasahero_" + dttm + "." + ext
        dl_file.value.click()

        /*
                              
          const reader = new FileReader();
            
          reader.onload = function(e) {

              storeVars.build_version = "adding new pic"

              // Convert the image to a Base64 string
              const base64String = e.target.result;
              useFuncs.log( base64String )
              const pic = {
                date: useFuncs.DateTimeFormat( new Date() ),
                img: base64String
              }                       

              useFuncs.local.add('pics', pic)

              storeVars.offline_data.pics.push( pic )
              storeVars.with_offline_data = true

              const pics = useFuncs.local.get('pics')

              storeVars.build_version = 
                `${storeVars.offline_data.pics.length} ${pics.length}`

          };
          // Read the image file as a Data URL (Base64 string)
          reader.readAsDataURL(file);    
        */

      }
    }

  onMounted(() => {
    storeVars.view_bg = "bg-grey-lighten-2"

    storeVars.NavBack(false)

    if ( storeVars.is_logged_in) 
      storeVars.EntryCounts()    
    
  })


  // cache fare matrix
  if ( storeVars.is_logged_in ){

    useFuncs.Post( {fn: 'fetch_image', fare_matrix_check: 1, json:1 }, (ret) => {

      if ( ret.status = "success"){        

        const local_date = localStorage.getItem("fare-matrix-date")
        const date = ret.date

        useFuncs.log( ret, local_date)

        // get new farematrix if local date vs data date not same
        if ( local_date != date ){

          // get new matrix image file
          useFuncs.Post( {fn: 'fetch_image', fare_matrix: 1, json: 1 }, (ret) => {
            
            const img = `data:${ret.file_type};base64,${ret.image}`; 

            localStorage.setItem('fare-matrix-date', date)
            localStorage.setItem('fare-matrix-img', img)

          })
        }
      }      
    })

  }

  const captured_qr_data = ( key ) => {    

    const qr = {
      datetime: useFuncs.DateTimeFormat( new Date() ), 
      profile_name: storeVars.qr_data.name,
      license_no: storeVars.qr_data.license_no,
      mobile_no: storeVars.qr_data.mobile_no,
    }
    
    try{    
      
      const data = useFuncs.local.add( key, qr )

      if ( key == "drivers"){
        storeVars.offline_data.qr_drivers = data

      }else{  // vehicles
        storeVars.offline_data.qr_vehicles = data
      }
      storeVars.with_offline_data = true

    }catch(err){

      storeVars.snackbar_message("Unable to store offline data. Please remove old data to free memory space.", "", 5)

    }
  }

</script>

<template>

<!-- <v-container class="px-8" > -->
  

  <v-row class="align-center px-5 " flat>
  
    <v-col cols=12 sm=6 
    v-if="storeVars.IsActive(CAT_Incidents)  && storeVars.is_logged_in" 
    >
      <v-card :color="bg[CAT_Incidents]"
        link
        @click="view_to( CAT_Incidents )"
        elevation="6"
      >
        <v-card-title class="text-h5 d-flex">
          Report Incident
          <v-spacer></v-spacer>
          <EntriesCounter :type="CAT_Incidents" />          
        </v-card-title>

        <v-card-text>
          Submit an Accident, Fire, Violence or any incident near your area.
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols=12 sm=6 
      v-if="storeVars.IsActive(CAT_LeftItems) && storeVars.is_logged_in " 
    >
      <v-card :color="bg[CAT_LeftItems]" 
        link
        @click="view_to( CAT_LeftItems )"
        elevation="6"
      >

        <v-card-title class="text-h5 d-flex">
          Left Behind Items
          <v-spacer></v-spacer>
          <EntriesCounter :type="CAT_LeftItems" />
        </v-card-title>

        <v-card-text>
          Cant find it? Send details about what's missing.
        </v-card-text>
      </v-card>

    </v-col>

    <v-col cols=12 sm=6 
      v-if="storeVars.IsActive(CAT_DriverComplains) && storeVars.is_logged_in" 
    >
      <v-card :color="bg[CAT_DriverComplains]"
        link
        @click="view_to( CAT_DriverComplains )"
        elevation="6"
      >

        <v-card-title class="text-h5 d-flex">

          Driver Complain
          <v-spacer></v-spacer>          
          <EntriesCounter :type="CAT_DriverComplains" />
          <v-icon 
            @click="scan_qr_code"
            class="ml-3"
          >mdi-qrcode-scan
          </v-icon>            

        </v-card-title>        

        <v-card-text>
          Something wrong with your ride? Let us know what happen.
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols=12 sm=6
      v-if="storeVars.IsActive(CAT_DriverRatings) && storeVars.is_logged_in " 
    >

      <v-card :color="bg[CAT_DriverRatings]"      
        link 
        @click="view_to( CAT_DriverRatings )"
        elevation="6"
      >
        <v-card-title class="text-h5 d-flex">
          Driver Rating
          <v-spacer></v-spacer>
          <EntriesCounter :type="CAT_DriverRatings" />          
          <v-icon 
            @click="scan_qr_code"
            class="ml-3"
          >
            mdi-qrcode-scan
          </v-icon>            
        </v-card-title>

        <v-card-text>
          The ride went well... or not. A comment and rate won't hurt.
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols=12 sm=6
      v-if="storeVars.IsActive(CAT_SafetySelfies) && storeVars.is_logged_in" 
    >
      <v-card :color="bg[CAT_SafetySelfies]" 
        link
        @click="view_to( CAT_SafetySelfies )"
        elevation="6"
      >
        <v-card-title class="text-h5 d-flex">
          Safe Ride Selfie
          <v-spacer></v-spacer>
          <EntriesCounter :type="CAT_SafetySelfies" />
        </v-card-title>

        <v-card-text>
          Feeling unsafe? Better send a selfie.
        </v-card-text>
      </v-card>

    </v-col>

    <v-col cols=12 sm=6>
      <v-card :color="bg[CAT_FareMatrix]"
        link
        @click="view_to(CAT_FareMatrix)"
        elevation="6"
      >
        <v-card-title class="text-h5">
          Fare Matrix
        </v-card-title>
        <v-card-text class="">
          Check the reasonable fare of your destination.
        </v-card-text>
      </v-card>

    </v-col>
   
    <v-col 
    v-if="storeVars.is_logged_in == false"
    cols=12 sm=6
    >

      <v-card 
        color="blue-grey-darken-2"
        elevation="6"
      >
        <v-card-title class="text-h5 text-blue-lighten-3">
          Capture Offline Data
        </v-card-title>
        <v-card-text class="">
          While offine, you can scan QR Codes or capture Images then use it later when your back online.
        </v-card-text>

        <v-row
          class="ma-1 gap-1"

        >          
          <v-col cols=4
          :class="`${useVars.offline_colors.qr_vehicle} d-flex flex-column align-center`"
          >
            <ReadQRCode @accept-click="captured_qr_data('vehicles')"/>
            <p
            class="mt-1"
            > 
              Scan Vehicle QR
            </p>

          </v-col>

          <v-col cols=4
          :class="`${useVars.offline_colors.qr_driver} d-flex flex-column align-center `"
          >
            <ReadQRCode @accept-click="captured_qr_data('drivers' )"/>
            <p
            class="mt-1"
            >
             Scan Driver QR
            </p>
          </v-col>

          <v-col cols=4
          :class="`${useVars.offline_colors.pic} d-flex flex-column align-center` "
          @click="take_picture"          
          >
            <v-icon 
            class="text-h4"             
            >
              mdi-camera
            </v-icon>

            <p class="mt-1">
              Capture Image
            </p>

            <input ref="camera" capture="camera" type="file" accept="image/*"  
              hidden @change="save_image_locally"
            >

            <a href="#" ref="dl_file" class="d-none">aaaaa</a>

          </v-col>

        </v-row>

        <div class="mx-1 mb-2">
        <OfflineData section='home'/>
        </div>
        
      </v-card>

    </v-col>

  </v-row>


</template>

