<script setup>
	/* eslint-disable */	

	import { ref, defineProps, onUpdated, onMounted } from 'vue'
	import { useVarsStore } from '@/store/DataStore.js'

	import { useFuncs, useVars } from '@/composables/useFunctions.js';

	import ImagesChip from '@/components/parts/ImagesChip.vue'

	const storeVars = useVarsStore()

	const props = defineProps(['type'])
	const txt_search = ref(null)

	const searched_data = ref([])

	let img_clicked = false

	// funcs
		const search_now = () => {

			img_clicked = false

			if ( ! storeVars.searched_profile.name ) return

			storeVars.show_busy = true		
			
			searched_data.value = []

			const p = {fn: "search-drivers", txt: storeVars.searched_profile.name, json: 1}		

			useFuncs.Post( p, (ret) => {

				storeVars.show_busy = false
				
				if ( ret.status == "failed"){
					storeVars.snackbar_message("Nothing found.")
				}else{

					searched_data.value = ret.data
				}

				txt_search.value.focus()
			})
		}

		const item_selected = (item) => {

			if ( img_clicked ){
				img_clicked = false
				return
			} 

			// console.log('item', item)
			storeVars.searched_profile.id = item.id
			storeVars.searched_profile.name = useFuncs.ProfileName(item)

			storeVars.search_show = false
		}
		const dud = () => {
			img_clicked = true;
			// none
		}

	// hooks
		onUpdated( () => {
		
			if ( storeVars.search_show ){
				searched_data.value = []
				if ( storeVars.searched_profile.name ) search_now()
			}

		})


		
</script>

<template>

	<v-bottom-sheet v-model="storeVars.search_show" persistent>
    
    <div class="bg-white pa-3">
    	
			<div 
			  class="d-flex"
			  style="margin-top: -30px;"            
			>
			  <v-btn 
			    class="ml-2"
			    icon="mdi-arrow-left" 
			    color="grey-lighten-2" 
			    density="comfortable"              
			    @click="storeVars.search_show = false"              
			  ></v-btn>
			</div>  

			<v-text-field 
				ref="txt_search"
        class="mt-4"
        label="Search" v-bind="useVars.field_props" 
        append-inner-icon="mdi-magnify"
        v-model="storeVars.searched_profile.name"
        @click:appendInner="search_now"
        clearable        
        >              
     	</v-text-field> 

     	<div 
   			v-if="searched_data.length"
   			class="border-sm h-200 scroll-y mt-2 py-2" >	
				
				<v-card
					v-for="(item, i) in searched_data" :key="i"
					class="mt-1 mx-2 pa-1 py-2 text-body-2 bg-light-blue-lighten-5"
					elevation="1"
					
					>
					<v-row
						no-gutters						
						@click="item_selected(item)"
						>
						<v-col cols="3" class="d-flex justify-center">
							<div @click="dud">
								<ImagesChip :images="item.images"/>
							</div>
						</v-col>
						<v-col cols="9">
							{{ useFuncs.ProfileName(item) }}
						</v-col>
					</v-row>
				</v-card>

     	</div>

    </div>

  </v-bottom-sheet>	

</template>