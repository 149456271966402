
<script setup >
	/* eslint-disable */
	
	import { ref, onMounted } from 'vue'
	import { useRouter } from 'vue-router';

  import { useVars, useFuncs, useRuleNotEmpty } from '@/composables/useFunctions.js'
  import { useVarsStore, useIncidentsStore } from '@/store/DataStore.js'

  import EntriesHistory from '@/components/EntriesHistory.vue'
  import PostEntry from '@/components/PostEntry.vue'  


  const storeVars = useVarsStore()
  const storeIncidents = useIncidentsStore()

  const router = useRouter()

  const cam_pic = ref(null)
  const img = ref(null)
  const form = ref(null)

  const message = ref('')
  const entries = ref([])

  const show_img = ref('d-none')  

  // =============== functions ===========
    
    const back_home = () => {
      
      storeVars.NavBack(false) 
      storeVars.view_bg = "bg-blue-grey-lighten-4"
      router.push("/")   
    }

	  const take_pic = () => {
	  	cam_pic.value.click()
	  }

	  const previewImage = () =>{

	  	storeVars.show_busy = true

	  	useFuncs.PreviewCapturedImage( cam_pic, ( src ) => {
	  		
	      // resize pic	      
	      const file = cam_pic.value.files[0]
	      useFuncs.ResizeImageQuality(file, 500, (blob) => {

	        if ( blob !== null ){       

	          let name = file.name
	          if ( storeVars.is_mobile ){             
	            name = useFuncs.PicFileName( name )           
	          }
	          
	          const _file = new File( [blob], name, { type: file.type } )

	          storeVars.att_files = [ _file ]     
	        
	        }else{
	          storeVars.att_files = [ file ]

	        }

	        storeVars.show_busy = false
	      })

	      img.value.src = src
	      show_img.value = true

	  	})

	  }

 		const submit = async () => {

      const { valid } = await form.value.validate()

      if (! valid) return

      if ( ! storeVars.att_files.length ){
				
				const { message } = storeVars
				message.text = "Please take a selfie."
				message.msecs = 3000
				message.show = true
				return

      }

	    const { prompt_message } = storeVars
	    prompt_message.title = "C A U T I O N !"
	    prompt_message.message = useVars.submit_text
	    prompt_message.yes_caption = "I Agree"
	    prompt_message.no_caption = "Abort"
	    prompt_message.color = "bg-blue-grey-darken-4"	    
	    prompt_message.persistent = true
	    prompt_message.show = true
	    prompt_message.yes_func = () => {

	    	const _data = { fn: 'insert', table: useVars.TABLE_Comments, json:1 }

	    	_data.data_type = useVars.CAT_SafetySelfies
	    	_data.description = message.value

	    	// attach files
        useFuncs.AttachFiles( _data )  

				_data.source = useVars.SOURCE_Mobile
	      _data.respondent_id = storeVars.user_profile.id
	      _data.respondent_name = storeVars.user_profile.name
	    	// _data.profile_id = 0


	      storeVars.show_busy = true     

	      storeVars.snackbar_message("Thank you for submitting a safety selfie report.");

	      // if off line

	      useFuncs.Post( _data, ( ret ) => {

	      	// console.log( 'selfy posting', ret)

	        storeVars.show_busy = false
	        back_home()

	      })        

	    }	
		}

		const save_local = () => {

			storeVars.snackbar_message("Your safety selfie report is temporarily saved in this device.");

			const selfie = {
				date: useFuncs.DateTimeFormat( new Date() ),
				pic: img.value.src
			}
			useFuncs.local.add( 'selfie', selfie)

			// useFuncs.log( 'selfie', selfie)

		}

	// ======== hooks ======
		onMounted(() => {

			storeVars.att_files = []




		})

	// ==== computed =====


</script>

<template >
	
	<div class=" " >

		<input ref="cam_pic" capture="camera" type="file" accept="image/*"  
			hidden @change="previewImage"
		>
		
 		<!-- <p class="text-h6 text-grey-lighten-3 text-center ">{{ storeIncidents.record.title }}</p> -->
 		<p class="text-h6  text-center ">{{ storeIncidents.record.title }}</p>

		<v-form ref="form"  >
 		<v-card class="px-5" 
 		elevation="10"
 		>

		<v-row class="py-5" no-gutters>
 			<v-col align="center">

 				<v-btn class="text-h5"
 					icon = "mdi-camera"
 					@click="take_pic"
 				> 					
 				</v-btn>				
				
				<div 
					:class="`${show_img} justify-center bg-grey-darken-4 pa-3 mt-3 rounded`">
					<img ref="img" style="width: 60%"></img>

				</div>
			</v-col>
		</v-row>		

		<v-row no-gutters>
		  <v-textarea  
		  	v-model.trim="message"
		  	label="Message:"
		  	v-bind="useVars.field_props" 
        rows="5"
        :rules="useRuleNotEmpty"
       >
      </v-textarea>
		</v-row>

		<v-row class="py-5" no-gutters>

			<v-col align="center">
	
				<v-btn 
					prepend-icon="mdi-send-circle text-green" 
					class="mr-2 mt-1"
					rounded
					@click="submit"
				>
					Submit Selfie
				</v-btn>

		    <v-btn 		    
		    class="mt-1"
		    rounded
		    @click="back_home"
		    >
		      <v-icon class="text-orange mr-2">mdi-arrow-left-circle</v-icon>
		      Back
		    </v-btn>  
			</v-col>

		</v-row>
		
		</v-card>
 		</v-form>

    <!-- <p class="text-subtitle-2 font-italic font-weight-light text-grey-lighten-4 mt-2"> -->
    <p class="text-subtitle-2 font-italic font-weight-light mt-2">
      {{ storeIncidents.record.note }}
    </p>

    <EntriesHistory :type="useVars.CAT_SafetySelfies" />

	</div>

	<PostEntry />
	
</template>

