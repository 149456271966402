/* eslint-disable */

import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import SelfieView from '../views/SelfieView.vue'
import IncidentView from '../views/IncidentView.vue'
import FareMatrix from '../views/FareMatrix.vue'
import LoginView from '../views/LoginView.vue'
import RegistrationView from '../views/RegistrationView.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: MainView
  },
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },  
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },  
  {
    path: '/selfie',
    name: 'selfie',
    component: SelfieView
  },
  {
    path: '/incident',
    name: 'incident',
    component: IncidentView
  },
  {
    path: '/incident/:qr',
    name: 'incident-qr',
    component: IncidentView
  },
  {
    path: '/fare-matrix',
    name: 'farematrix',
    component: FareMatrix
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
