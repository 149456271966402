<script setup>
  /* eslint-disable */

  import { ref, computed, onMounted, onBeforeUpdate } from 'vue'
	import { useRoute, useRouter } from 'vue-router';

  import { useDisplay } from 'vuetify'

  import { useFuncs, useVars, useRuleNotEmpty } from '@/composables/useFunctions.js';

  import { useVarsStore, useIncidentsStore } from '@/store/DataStore.js'
  
  import SaveCancel from '@/components/parts/SaveCancel.vue'
  import AttachFiles from '@/components/parts/AttachFiles.vue';
  import ReadQRCode from '@/components/parts/ReadQRCode.vue'

  import EntriesHistory from '@/components/EntriesHistory.vue'
  import PostEntry from '@/components/PostEntry.vue'  
  import SearchDrawer from '@/components/parts/SearchDrawer.vue'
  import OfflineData from '@/components/OfflineData.vue'

  const { smAndUp } = useDisplay()  

  const router = useRouter()

  const storeIncidents = useIncidentsStore()
  const storeVars = useVarsStore()

  const form = ref(null)
  const qr = ref(null)
  const txt_profile = ref(null) 
  
  const data = ref({
  	data_type: '', incident_type: '', 
  	description: '', location:'', status: '', image_id: '', 
    respondent_id: '', respondent_name: '',
    profile_id: '', profile_name: '', profile_license_no: '', profile_mobile_no: '',   
    datetime_event: '', rating: 0, commment_type: 0
  })

  const files = ref([])
  const logs = ref('') 

  const {CAT_Incidents, CAT_LeftItems, CAT_DriverComplains, 
    CAT_DriverRatings, field_props,
    TABLE_Incidents, TABLE_Comments } = useVars

  const { record } = storeIncidents

  // functions ==============

    const back_home = () => {
      
      storeVars.NavBack( false )
      storeVars.view_bg = "bg-blue-grey-lighten-4"
      router.push("/")   

    }
    
    const postIt = ( _data ) => {
      
      // console.log( 'data', _data)
      // return

      storeVars.show_busy = true

      // respondent / reporter person
      _data.source = useVars.SOURCE_Mobile      
      _data.respondent_id = storeVars.user_profile.id
      _data.respondent_name = storeVars.user_profile.name

      const type = storeIncidents.record.type

      const group_type = {
        [CAT_Incidents] : "an incident",
        [CAT_LeftItems] : "a missing/left item",
        [CAT_DriverComplains] : "a driver complain",
        [CAT_DriverRatings] : "a driver rating",
      }
      storeVars.snackbar_message(`Thank you for submitting ${group_type[type]} report. PasaHero will be in touch with you shortly.`)

      useFuncs.Post( _data, ( ret ) => {

        logs.value = ret

        storeVars.show_busy = false
        storeIncidents.Close()
        back_home()

      })

    }


	  const submit = async () => {

      const { record } = storeIncidents

      const isComment = [ CAT_DriverRatings, CAT_DriverComplains ].includes(record.type)

      const { valid } = await form.value.validate()

      if (! valid) return

      // image not required, enable if rule is available
      /*    
      if ( ! storeVars.att_files.length ){
        storeVars.snackbar_message("Please attach image.", 5)
        return
      }
      */
      
      const { prompt_message } = storeVars

      prompt_message.title = "C A U T I O N !"
      prompt_message.message = useVars.submit_text
      prompt_message.yes_caption = "I Agree"
      prompt_message.no_caption = "Abort"
      prompt_message.persistent = true
      prompt_message.color = "bg-blue-grey-darken-4"
      prompt_message.show = true      
      prompt_message.yes_func = () => {

  			const _data = {fn: 'insert', table: TABLE_Incidents, json: 1}

        if ( isComment ){
          _data.table = useVars.TABLE_Comments
        }     

        _data.description = data.value.description
        _data.data_type = record.type

        _data.location =        data.value.location
       
        if( isComment ){
    
          delete _data.location

          // driver in complain
          _data.profile_id =   storeVars.searched_profile.id
          _data.profile_name = storeVars.searched_profile.name

          if ( record.type == CAT_DriverComplains )
            _data.comment_type = data.value.comment_type.value

          if ( record.type == CAT_DriverRatings )
            _data.rating = data.value.rating

        }

        // incident type
        if ( record.type == CAT_Incidents )
            _data.incident_type =  data.value.incident_type.value

        // datetime_event
        if ( record.type == CAT_LeftItems ){
          _data.datetime_event = data.value.datetime_event
        }

        // attach files
        useFuncs.AttachFiles( _data )      
        useFuncs.AttFilesDelete( postIt( _data ) )

      }
	  }

	  const searchProfile = () => {

      if ( record.type == CAT_DriverComplains || record.type == CAT_DriverRatings){        
        txt_profile.value.blur()
        storeVars.search_show = true
      }
	  }

    
    const labels = ( field ) => {

      let items = {}

      if ( field == "location"){

        items[ CAT_Incidents ] = 'Location:'
        items[ CAT_LeftItems ] = 'Tricycle body/plate no:'

      }else if( field == "description"){

        items[ CAT_Incidents ] = 'Description:'
        items[ CAT_LeftItems ] = 'Details:'
        items[ CAT_DriverComplains ] = 'Complain Details:'       
        items[ CAT_DriverRatings ] = 'Comment:'       
        
      }else if( field == "respondent"){

        items[ CAT_Incidents ] = 'Respondent:'
        items[ CAT_LeftItems ] = 'Owner:'
        items[ CAT_DriverComplains ] = 'Complainant:'       
        items[ CAT_DriverRatings ] = 'Respondent:'       

      }else if( field == "driver"){
        items[ CAT_DriverComplains ] = 'Offending Driver:'       
        items[ CAT_DriverRatings ] = 'Driver:'       

      }

      return items[ storeIncidents.record.type ]
    }

    const captured_qr_data = () => {
      // console.log('qr data', storeVars.qr_data )
      data.value.profile_name = storeVars.qr_data.name
      data.value.profile_license_no = storeVars.qr_data.license_no
      data.value.profile_mobile_no = storeVars.qr_data.mobile_no

    }

    const selected_qr_data = (type, qr_data) => {

      const add_to_desc = () => {

        let desc
        if ( type == 'drivers'){
          desc = [
            'Driver: '+ qr_data.profile_name,
            "Driver's License: " + qr_data.license_no,
            "Contact No: "+ qr_data.mobile_no
          ]

        }else{ // vehicles
          desc = [
            'Owner: '+ qr_data.profile_name,
            "Plate No: " + qr_data.license_no,
            "Body No: "+ qr_data.mobile_no
          ]

        }
        data.value.description += "\r" + desc.join("\r")
      }


      if ( record.type == CAT_DriverComplains || 
           record.type == CAT_DriverRatings ){
        
        storeVars.show_busy = true

        // check license no if exists
        useFuncs.Post( {fn: 'licno-exists', no: qr_data.license_no, json:1 }, (ret) => {
          
          storeVars.show_busy = false

          if ( ret.status == "success"){

            storeVars.searched_profile.id = ret.data.id
            storeVars.searched_profile.name = qr_data.profile_name
          }else{

            useFuncs.ShowMessage({
              message: "Driver profile not found. Would you like to add it in details instead?",
              color: 'bg-grey-darken-2',
              persistent: true,
              yes_func: () => {
                add_to_desc()
              }
            })        

          }
        })

      }else{
        add_to_desc();
      }

    }

	// computed =============

	  const title = computed( () => {
      
      const type = storeIncidents.record.type      

	  	let title = useFuncs.CustomTypes( useVars.category_types).Title( type ) 		  	

	  	return title
    })

  // hooks ===============

    onMounted( () => {     
      
      // defaults
      if ( record.type == CAT_LeftItems ){
        const dt = useFuncs.DateFormat( Date.now(), "Y-m-d" ) 
        const tm = useFuncs.TimeFormat( Date.now(), "h:m")
        data.value.datetime_event = dt + "T" + tm
      }

      storeVars.post_record.show = false

      // clear searched profile model
      storeVars.searched_profile.name = ''
      storeVars.searched_profile.id = ''

    })

    onBeforeUpdate( () => {
      
      const { record } = storeIncidents

      useFuncs.ResetObject( data )               
      files.value = []      

      if ( record.is_add && record.type == CAT_DriverRatings )
        data.value.rating = 0

    })

    

</script>


<template>

   <div class="">
    
    <!-- <p class="text-h6 text-grey-lighten-3 text-center ">{{ storeIncidents.record.title }}</p> -->
    <p class="text-h6  text-center ">{{ storeIncidents.record.title }}</p>

    <v-card class="w-100" elevation="10">

      <v-form ref="form"  >

      <v-card-text class="ma-3 mb-3">
                
        <!--  rating  -->
        <v-row v-if="storeIncidents.record.type == CAT_DriverRatings"        
        >            
          <v-col cols=12 class="d-flex gap-2 justify-center">

            <v-rating 
              v-model="data.rating"
              active-color="blue"
              color="orange-lighten-1"
              hover
              size="large"  
              :density=" smAndUp ? 'comfortable': 'compact'"
              half-increments      
              class="mt-2"
            ></v-rating>

            <span class="text-h3 ml-1">{{ data.rating }}</span>
            <span class="text-h6 ml-1 mt-4">/5</span>

          </v-col>
          

        </v-row>  

        <!-- incident type -->
        <v-row v-if="storeIncidents.record.type == CAT_Incidents"           

        >
          <v-col xs=12 sm=6 compact>
            <v-select
              v-model="data.incident_type"
              label="Incident Type:"
              :items="useVars.incident_types"
              item-title="title"
              item-value="value"                      
              v-bind="field_props"
              :rules="useRuleNotEmpty"
              return-object
              hidedetails = true
            ></v-select>                    
          </v-col>          
        </v-row>

        <!-- location -->
        <v-row           

          v-if="[ CAT_Incidents, CAT_LeftItems].includes( record.type) "
          >            
          <v-col >
            <v-textarea             
            :label="labels('location')" v-bind="field_props" :rules="useRuleNotEmpty"
            v-model.trim="data.location"
            :rows=" smAndUp ? 1 : 2"
            >              
            </v-textarea>

          </v-col>
        </v-row>

        <!-- comment type -->
        <v-row v-if="storeIncidents.record.type == CAT_DriverComplains" 
        >
          <v-col sx=12 sm=6>
            <v-select
              v-model="data.comment_type"
              label="Complain Type:"
              :items="useVars.driver_complain_types"
              item-title="title"
              item-value="value"                      
              v-bind="field_props"
              :rules="useRuleNotEmpty"
              return-object
            ></v-select>                    
          </v-col>          
        </v-row>

        <!-- description -->
        <v-row 
        >
          <v-col >
            <v-textarea :label="labels('description')" v-bind="field_props" 
            :rules="useRuleNotEmpty"
            v-model.trim="data.description"
            :rows="smAndUp ? 2 : 4"
            >              
            </v-textarea>
          </v-col>
        </v-row>

        <!-- when -->
        <v-row 
          v-if="storeIncidents.record.type == CAT_LeftItems"
          >
          <v-col :cols=" storeVars.is_mobile ? 12 : 'auto'">
            <v-text-field  label="When?" type="datetime-local" v-bind="field_props" 
            v-model="data.datetime_event"
            :rules="useRuleNotEmpty"
            class="w-100"
            > </v-text-field>     
          </v-col>

        </v-row>

        <!-- defendant (driver name) -->
        <v-row 
          v-if="storeIncidents.record.type == CAT_DriverComplains || storeIncidents.record.type == CAT_DriverRatings"
          >
          <v-col class="d-flex align-center">

            <ReadQRCode @accept-click="captured_qr_data"/>

            <v-text-field 
            ref="txt_profile"
            class="ml-2"
            :label="labels('driver')" v-bind="field_props" 
            append-inner-icon="mdi-account-search"
            v-model.trim="storeVars.searched_profile.name"
            :rules="useRuleNotEmpty"
            @click:appendInner="searchProfile"            
            >              
            </v-text-field>
          </v-col>
        </v-row>

        <!-- attach file  -->
        <v-row  
        >        
          <v-col cols="12">
            <AttachFiles :images="data.images"/>
          </v-col>              
        </v-row>

        <!-- <v-row
          v-if="useFuncs.DEV_MODE()"
        >
            <v-col>           
            <div>logs: {{ JSON.stringify(logs) }}</div>
            <div> store_logs: {{ storeVars.logs }}</div>
            is mobile: {{ storeVars.is_mobile }}
          </v-col>
        </v-row> -->

      </v-card-text>

      </v-form>

      <SaveCancel 
        :can_delete="false"
        @save-click="submit" 
        @cancel-click="back_home"        
      />

    </v-card>      

    <p class="text-subtitle-2 font-italic font-weight-light text-grey-lighten-4 mt-2">
      {{ storeIncidents.record.note }}
    </p>

    <EntriesHistory :type="record.type" />
    
    <OfflineData @get-offline-data="selected_qr_data" section='record.type'/>

    <SearchDrawer type="Driver" />

  </div>
  
  <PostEntry />

</template>